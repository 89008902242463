import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import services from './services';
import { ServicesContext } from './context/services'; 

import './App.scss';
import Home from './pages/Home';
import Intro from './pages/Intro';
import Dashboard from './pages/Dashboard';
import Detail from './pages/Detail';
import Portfolio from './pages/Portfolio';

import i18n from "./config/i18n";

function App() { 
  return ( 
    <ServicesContext.Provider value={{ services }}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/intro" component={Intro} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/portfolio/:slug" component={Portfolio} />
          <Route exact path="/:type" component={Dashboard} />
          {/* <Route path="/:type/:slug" component={Detail} /> */}
          <Route path="/detail/:slug" component={Detail} />
          <Route path="/detalhe/:slug" component={Detail} />
          <Route path="/program/:slug" component={Detail} />
          <Route path="/solution/:slug" component={Detail} />
        </Switch> 
      </Router>
    </ServicesContext.Provider>
  );
}

export default App;
