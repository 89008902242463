import React, { Component } from "react";
import { withTranslation  } from 'react-i18next';
import './Header.scss';
import Language from './shared/Language';

class Header extends Component {
    render() {
        const { page } = this.props;
        return (
            <header className='header'>
                { page === "home" ? <figure className="logo-novasbe"></figure> : null}
                { page === "home" ? <Language /> : null }
            </header>
        )
    }
}
export default withTranslation()(Header);
