import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { ServicesContext } from '../../../context/services';
import Loading from '../Loading';
import './TagList.scss';

const STORAGE_LABEL = 'iter:filter:taglist';
const savedOptions = JSON.parse(localStorage.getItem(STORAGE_LABEL));

class TagList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: savedOptions && savedOptions.length > 0 ? savedOptions : [],
            animate: false
        };
    }

    componentDidMount() {
        if (!savedOptions || savedOptions.length === 0) {
            const { services } = this.context;
            services.filtersApi.getAllFocus()
                .then(({ allTransformationFoci }) => {
                    this.setState({ options: this.mergeDuplicateLabels(allTransformationFoci) });
                }).catch(error => console.log(error));
        }

        if (window.innerWidth > 767) {
            setTimeout(() => {
                this.setState({ animate: true })
            }, 1000);
        } else this.setState({ animate: true });
    }

    reset() {
        if (this.state.options) {
            const options = this.state.options;
            options.filter(option => {
                option.checked = false;
            });
            localStorage.setItem(STORAGE_LABEL, JSON.stringify([]));
        }
    }

    handleChange(index, event) {
        const options = this.props.type && this.props.type.length ? this.parseByType(this.state.options) : this.state.options;
        options[index].checked = event.currentTarget.checked;

        const optionChecked = options.filter(option => {
            if (option.checked === true) return option;
        });
        
        this.props.onFilterChange(this.props.id, optionChecked);
        localStorage.setItem(STORAGE_LABEL, JSON.stringify(options));
    }

    parseByType(list) {
        let newList = [];
        let selected = {}, item, i, k;

        for (i = 0; i < this.props.type.length; i++) {
            if (this.props.type[i].checked) {
                selected[this.props.type[i].id] = true;
            }
        }

        const addTag = item => {
            if(!newList.some(tag => tag.pt === item.pt)) newList.push(item);
        }

        for (i = 0; i < list.length; i++) {
            item = list[i];
            for (let v = 0; v < item.types.length; v++) {
                const type = item.types[v];
                if(selected[type.id]) addTag(item)
            }

            // if (selected[item.type.id]) {
            //     newList.push(item);
            // }
        }

        return newList;
    }

    mergeDuplicateLabels(list){
        let newList = [];

        const findTag = tag_in => {
            let found = false;
            for (let v = 0; v < newList.length; v++) {
                let tag = newList[v];
                if(tag.pt === tag_in.pt){
                    tag.ids.push(tag_in.id);
                    tag.types.push(tag_in.type);
                    found = true;
                }
            }
            if(!found){
                tag_in.ids = [tag_in.id];
                tag_in.types = [tag_in.type];
                newList.push(tag_in);
            }
        }

        for (let i = 0; i < list.length; i++) {
            let tag = list[i];
            findTag(tag);
        }
        return newList;
    }

    render() {
        const { id, title, t, i18n } = this.props;
        const { options, animate } = this.state;

        let parsed;

        if (options) {
            parsed = this.props.type && this.props.type.length ? this.parseByType(options) : options;
        }

        return (
            <div className="taglist-root m-top-50">
                <div className="row m-top-35" id={id}>
                    <div style={{ transitionDelay: "50ms" }} className={animate ? "row animated anim" : "row animated"}>
                        <h4 className="f-f-flayfair f-bold f-s-14">{title}</h4>
                    </div>
                    <div className="row taglist">
                        {parsed ? parsed.map((item, index) => (
                            <div key={item.id} style={{ transitionDelay: 65 * index + "ms" }} className={animate ? "animated anim" : "animated"}>
                                <div className={item.note ? "tag note" : "tag"} >
                                    <input type="checkbox" id={item.id} name={id} onChange={this.handleChange.bind(this, index)} checked={item.checked ? true : false} />
                                    <label htmlFor={item.id}>{item[i18n.language]} {item.note ? <span className="icon-info"></span> : null} <div className="color" style={{ backgroundColor: item.color }}></div></label>
                                    {item.note ?
                                        <div className="tooltip">
                                            <div className="row">
                                                <span className="f-f-flayfair f-bold f-s-14 c-darkblue">{item[i18n.language]}</span>
                                            </div>
                                            <div className="row m-top-10">
                                                <span className="f-s-12 c-darkblue">{t('filters.tooltip', 'By selecting this you can choose the type of sector in the Advanced Filters.')}</span>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>

                        )) : <Loading />}
                    </div>
                </div>
            </div>
        )
    }
}
TagList.contextType = ServicesContext;

export default withTranslation('translation', { withRef: true })(TagList);