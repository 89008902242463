import React from 'react';
import { useRef, useEffect } from 'react';
import SimplexNoise from "simplex-noise";

const colors = [
    "#B8C28D",
    "#A4BFA9",
    "#E7B52F",
    "#335992",
    "#2D386A",
    "#732561",
    "#882447",
    "#732561",
    "#D27C2F",
    "#DBB46E"
];

const AnimatedGraph = (props) => {
    let ref = useRef();

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');
    
        let w, h;
        let simplex;
        //let m, mx, my;
        let now;

        let radius = [];

        const minR = 20;
        const maxRStep = 6;
        const nrCircles = 26;
        
        const setup = () => {

            reset();
            window.addEventListener("resize", reset);

            for (let i = 0; i < nrCircles; i++) {
                radius.push((i + 1) * maxRStep + minR);
            }
        }

        const reset = () => {
            simplex = new SimplexNoise();
            w = canvas.width;
            h = canvas.height;
            //m = Math.min(w, h);
            //mx = w / 2;
            //my = h / 2;
        }

        let requestId;

        const render = (timestamp) => {
            now = timestamp;
            requestId = requestAnimationFrame(render);
            context.clearRect(0, 0, w, h);

            let counter = 0;
            for (let i = 0; i < nrCircles; i++) {
                if(counter === colors.length) counter = 0;
                drawCircle(radius[i], colors[counter++]);
            }

        };

        const drawCircle = (r, color) => {
            context.lineWidth = 1;
            context.strokeStyle = color;
            context.beginPath();

            let point, x, y;
            let deltaAngle = Math.PI * 2 / w;

            for (let angle = 0; angle < Math.PI * 2; angle += deltaAngle) {
                point = calcPoint(angle, r);
                x = point[0];
                y = point[1];
                context.lineTo(x, y);
            }
            context.closePath();
            context.stroke();
        }

        const calcPoint = (angle, r) => {
            let noiseFactor = 25;
            let zoom = 200;
            let x = Math.cos(angle) * r + w / 2;
            let y = Math.sin(angle) * r + h / 2;
            let n = (simplex.noise3D(x / zoom, y / zoom, now / 2000)) * noiseFactor;
            x = Math.cos(angle) * (r + n) + w / 2;
            y = Math.sin(angle) * (r + n) + h / 2;
            return [x, y];
        }
        /*
        const map_range = (value, low1, high1, low2, high2) => {
            return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
        }
        
        const randomNumber = (min, max) => {
            return Math.random() * (max - min) + min;
        }
        */
        setup();
        render();

        return () => {
            cancelAnimationFrame(requestId);
        };
    }, []);

    return (
        <canvas ref={ref} width={props.width || 1000} height={props.height || 1000} />
    );
};

export default AnimatedGraph;