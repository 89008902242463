import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { ServicesContext } from '../context/services';
import Helmet from 'react-helmet';
import './../App.scss';
import './Portfolio.scss';
import Nav from '../components/Navigation';
import PopupForm from '../components/shared/PopupForm';
import Card from '../components/shared/Card';
import Loading from '../components/ui/Loading';

import ReactToPrint from "react-to-print";
import queryString from 'query-string';

class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadForm: false,
            solutions: null,
            loading: true,
            campaign: null,
            currentPortfolio: null,
            printing: false,
        }
        this.onPopup = this.onPopup.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.componentRef = createRef();
        this.printRef = createRef();
    }

    getPortfolio(ids) {
        this.setState({ loading: true });
        const { services } = this.context;

        if (ids) {
            services.solutionsApi.getPortfolio(JSON.stringify(ids))
                .then(({ allSolutions }) => {
                    console.log(allSolutions);
                    if (allSolutions) this.setState({ solutions: allSolutions, currentPortfolio: ids, loading: false })
                });
        } else {
            this.setState({ solutions: [], loading: false })
        }
    }

    componentDidMount() {
        const { services } = this.context;
        const params = this.props.match.params;

        let ids;

        if (params && params.slug) {
            console.log(params.slug);
            services.campaignsApi.getBySlug(params.slug)
                .then(({ allCampaigns }) => {
                    if (allCampaigns && allCampaigns.length) {
                        let campaignSolutions = [];
                        for (let i = 0; i < allCampaigns[0].solutions.length; i++) {
                            const solutions = allCampaigns[0].solutions[i];
                            campaignSolutions.push(solutions.id);
                        }
                        localStorage.setItem('iter:portfolio', JSON.stringify(campaignSolutions));
                        ids = campaignSolutions;
                        this.setState({ campaign: allCampaigns[0].campaign_id });
                        this.getPortfolio(ids);
                    }
                });
        } else {
            ids = JSON.parse(localStorage.getItem('iter:portfolio') ? localStorage.getItem('iter:portfolio') : "[]");
            this.getPortfolio(ids);
        }
    }

    onPopup() {
        const popupState = this.state.downloadForm;
        this.setState({ downloadForm: !popupState });
    }

    onCardChange() {
        this.getPortfolio();
    }

    removeAllPortfolio() {
        localStorage.removeItem('iter:portfolio');
        this.setState({ solutions: [], currentPortfolio: [], loading: false })
    }

    onPrint() {
        this.setState({ printing: true });
        setTimeout(() => {
            this.printRef.current.handleClick();
        }, 500);
    }

    render() {
        const { t, i18n } = this.props;
        const { downloadForm, solutions, loading, currentPortfolio, printing } = this.state;

        const currentUserType = localStorage.getItem('iter:type');
        return (
            <div className="App bg portfolio">
                <Helmet title={i18n.language === "pt" ? "Iter by Nova SBE Executive Education - Portefólio" : "Iter by Nova SBE Executive Education - Portfolio"} />
                <PopupForm show={downloadForm} onClose={this.onPopup} portfolio={currentPortfolio ? currentPortfolio : []} campaign={this.state.campaign} onPrint={this.onPrint} />
                <div className="main ">
                    <Nav backUrl={true} campaign={this.state.campaign} />
                    <div className="wrapper">
                        <div className="row">
                            <span className="f-s-14 c-blue">{t('portfolio.subtitle', 'Choose to iter.act')}</span>
                        </div>
                        <div className="row m-top-5">
                            <h1 className="f-f-flayfair f-s-32 f-bold c-darkblue">{t('portfolio.title', 'Your Portfolio')}</h1>
                        </div>
                        <div className="row">
                            {
                                solutions && solutions.length > 0 ?
                                    <div>
                                        <div className="row flex m-top-30">
                                            <div className="button icon small" onClick={this.onPopup}><span className="icon-download"></span> <span>{t('btn.download_all', 'Download all programs')}</span></div>
                                            <div className="button icon small secundary m-left-15" onClick={this.removeAllPortfolio.bind(this)}><span className="icon-delete"></span> <span>{t('btn.remove_all', 'Remove all solutions')}</span></div>
                                        </div>
                                        <div className="row m-top-50" ref={el => (this.componentRef = el)}>
                                            <ul>
                                                {
                                                    solutions.map(solution => (
                                                        <li key={solution.id}>
                                                            <Card solution={solution} small={true} onChange={this.onCardChange.bind(this)} printing={printing} />
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    :
                                    loading ? <div className="m-top-20"><Loading /></div> :
                                        <div className="row a-center m-top-100">
                                            <div className="row">
                                                <img src="/images/empty-folder.svg" alt="Empty folder" />
                                            </div>
                                            <div className="row m-top-20">
                                                <span className="f-f-flayfair f-s-18 f-bold c-darkblue">{t('portfolio.empty_title', 'Your portfolio is empty')}</span>
                                            </div>
                                            <div className="row m-top-5">
                                                <span className="f-s-14 c-blue">{t('portfolio.empty_txt', 'Explore your journey and add experiences')}</span>
                                            </div>
                                            {currentUserType ?
                                                <div className="row m-top-20">
                                                    <Link to={'/' + currentUserType} target="_self" className="button secundary small">{t('portfolio.empty_btn', 'Your Journey')}</Link>
                                                </div> : null}
                                        </div>
                            }
                        </div>
                    </div>
                </div>
                <ReactToPrint
                    trigger={() => <React.Fragment />}
                    content={() => this.componentRef}
                    ref={this.printRef}
                    onBeforePrint={() => this.setState({ printing: true })}
                    onAfterPrint={() => {
                        this.setState({ printing: false });
                        this.onPopup()
                    }}
                />
            </div>
        )
    }
}

Portfolio.contextType = ServicesContext;

export default withTranslation()(Portfolio);