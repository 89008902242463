import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import './Card.scss';
import './Speaker.scss';

import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';


class Speaker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            video: null
        }

        this.onChange = this.props.onChange;
    }

    openVideo() {
        this.setState({ video: true })
    }

    getYouTubeId(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    getDetailURL(program) {
        let url = '';
        const slug = this.props.i18n.language === 'pt' ? program.url_pt : program.url_en;
        url += slug ? program.types.target + '/' + slug : 'detail/' + program.id;
        console.log('detail url', url);
        return url;
    }

    render() {
        const { t, i18n, solution } = this.props;
        const { video } = this.state;

        return (
            <div className="card speaker">
                <div className="row flex jc-sb" style={{ alignItems: 'flex-start' }}>
                    {solution.speaker_photo ? 
                    <div className="wrap_img">
                        <img src={solution.speaker_photo.publicUrl} alt="speaker_pic" className="responsive"></img>
                    </div>
                    : null}
                    <div className="row m-left-30">
                        <h1 className="f-f-flayfair f-s-12" >{solution.organization}</h1>
                        <h1 className="f-f-flayfair f-bold f-s-24 c-blue m-top-5" style={{ color: solution.primary_focus[0].color }}>{solution.speaker_name}</h1>
                        <p className="f-s-12">{i18n.language === "pt" ? solution.speaker_title_pt : solution.speaker_title_en}</p>
                        <div className="row m-top-15">
                            <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Bio" : "Bio"}</p>
                        </div>
                        <div className="row m-top-10">
                            <p className="f-s-14">{i18n.language === "pt" ? solution.speaker_bio_pt : solution.speaker_bio_en}</p>
                        </div>
                        <div className="row sep m-top-20 m-bottom-20"></div>
                        <h1 className="f-f-flayfair f-s-24">{i18n.language === "pt" ? solution.pt : solution.en}</h1>
                        <div className="row flex wrap">
                            <div className="row inline flex m-top-10  m-right-25">
                                <span className="icon-global" style={{ color: solution.primary_focus[0].color }}></span>
                                <span className="f-s-11 m-left-5">{i18n.language === "pt" ? solution.speaker_languages_pt : solution.speaker_languages_en}</span>
                            </div>
                            {solution.format.map(item => (
                                <>
                                    <div key={item.id} className="row inline flex m-top-10 m-right-25">
                                        <span className={item.icon} style={{ color: solution.primary_focus[0].color }}></span>
                                        <span className="f-s-11 m-left-5">{item[i18n.language]}</span>
                                    </div>
                                </>
                            ))}
                            <div className="row inline flex m-top-10  m-right-25">
                                <span className="icon-duration" style={{ color: solution.primary_focus[0].color }}></span>
                                <span className="f-s-11 m-left-5">{i18n.language === "pt" ? solution.duration_pt : solution.duration_en}</span>
                            </div>
                        </div>
                        <div className="row m-top-20">
                            <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Desafio" : "Challenge"}</p>
                        </div>
                        <div className="row m-top-10">
                            <p className="f-s-14">{i18n.language === "pt" ? solution.summary_pt : solution.summary_en}</p>
                        </div>
                        <div className="row flex m-top-15 bottom">
                            {solution.file_pt ? 
                            <a href={i18n.language === "pt" ? solution.file_pt.publicUrl : solution.file_en.publicUrl} target="_blank" className="button small secundary" style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }}>
                                <span>{t('btn.download', 'Download')}</span>
                            </a> : null }
                            {i18n.language === "pt" && solution.video_pt || i18n.language === "en" && solution.video_en ?
                                <div className="button small secundary m-left-10" style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }} onClick={this.openVideo.bind(this)}>
                                    {t('btn.watch_video', 'Watch video')}
                                </div> : null}
                        </div>
                        <div className="row sep m-top-35"></div>
                        <div className="row m-top-5">
                            <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>Contact us for more information:</p>
                            <div className="row flex wrap">
                                <div className="row inline flex m-top-10  m-right-25">
                                    <p className="f-s-12">Diogo Lobo de Carvalho</p>
                                </div>
                                <div className="row inline flex m-top-10  m-right-25">
                                    <span className="icon-mail" style={{ color: solution.primary_focus[0].color }}></span>
                                    <span className="f-s-11 m-left-5">diogo.carvalho@suportugal.org </span>
                                </div>
                                <div className="row inline flex m-top-10">
                                    <span className="icon-phone" style={{ color: solution.primary_focus[0].color }}></span>
                                    <span className="f-s-11 m-left-5"> 960 306 350</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {video ? <ModalVideo channel='youtube' isOpen={video} videoId={i18n.language === "pt" ? this.getYouTubeId(solution.video_pt) : this.getYouTubeId(solution.video_en)} onClose={() => this.setState({ video: false })} /> : null}
            </div>
        )
    }
}

export default withTranslation()(Speaker);