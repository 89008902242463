import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import './Navigation.scss';
import Language from './shared/Language';
import PopupWarning from './shared/PopupWarning';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

const currentLocation = window.location.pathname;

class Navigation extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            popup: false
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.onPortfolioHandler = this.onPortfolioHandler.bind(this);
    }

    toggleNav(e) {
        e.preventDefault();
        const { open } = this.state;
        this.setState({ open: !open });
    }

    onNavItemClick(type) {
        const currentUserType = localStorage.getItem('iter:type');
        currentUserType && type !== currentUserType ? this.setState({ popup: true }) : window.location = "/" + type;
    }

    onNewSearch (e) {
        e.preventDefault();
        if(this.props.onReset) this.props.onReset();
        else this.props.history.push('/journey');
    }

    onClosePopup() {
        this.setState({ popup: false, open: false });
    }

    onPortfolioHandler(e) {
        e.preventDefault();
        this.props.history.push('/portfolio');
    }

    toggleSidebar(e) {
        e.preventDefault();
        this.props.onToggleClick(e);
    }

    onOpenSearch(e) {
        e.preventDefault();
        this.props.openSearch();
    }

    render() {
        const { open, popup } = this.state;
        const { t, backUrl } = this.props;
        const jsonPortfolio = localStorage.getItem('iter:portfolio') ? localStorage.getItem('iter:portfolio') : "[]";
        const currentPortfolio = this.props.portfolio ? this.props.portfolio : JSON.parse(jsonPortfolio);

        return (
            <div>
                {open ? <div className="nav-bg"></div> : null}
                <div className="header-buttons">
                    {this.props.toggle ?
                        <div className="nav-btn toggleBtn" onClick={this.toggleSidebar.bind(this)}>
                            <MediaQuery minWidth={768}>
                                <span className={this.props.sidebar ? 'icon-arrow-left' : 'icon-arrow-right'}></span>
                            </MediaQuery>
                            <MediaQuery maxWidth={767}>
                                <span className="icon-filters"></span>
                            </MediaQuery>
                        </div>
                        : null}
                    {backUrl ?
                        <div className="nav-btn back-button">
                            { this.props.campaign ? 
                                <a href="/journey" className="button secundary small darkblue">{t('btn.explore_jorney', 'Explorar Jornada')}</a>
                            : 
                                <div onClick={this.props.history.goBack} className="button secundary small darkblue">{t('btn.back', 'Back')}</div>
                            }
                        </div>
                        : null}
                    {this.props.toggle ? <div className="nav-btn" onClick={this.onOpenSearch.bind(this)}><span className="icon-search"></span></div> : null}
                    <div className="nav-btn" onClick={this.onPortfolioHandler}><span className="icon-book"></span><span className="num">{currentPortfolio ? currentPortfolio.length : 0}</span></div>
                    <div className="nav-btn" onClick={this.toggleNav}><span className="icon-menu"></span></div>
                </div>
                <nav className={open ? "open" : ""}>
                    <div className="close-nav" onClick={this.toggleNav}><span className="icon-close"></span></div>
                    <div className="main-nav">
                        <div className="row anim" style={{ transitionDelay: "200ms" }}>
                            <a className={currentLocation === '/' ? 'main-nav-item active' : 'main-nav-item'} href="/" target="_self">{t('nav.home', 'Home')}</a>
                        </div>
                        {/* <div className="row m-top-30">
                            <div className="row anim" style={{ transitionDelay: "300ms" }}>
                                <span className="f-f-flayfair f-s-16 o-50">{t('nav.explore', 'Explore')}</span>
                            </div>
                            <div className="row m-top-5 anim" style={{ transitionDelay: "400ms" }}>
                                <div className={currentLocation === '/B2C' ? 'main-nav-item active' : 'main-nav-item'} onClick={this.onNavItemClick.bind(this, "B2C")}>{t('nav.individual', 'Individual')}</div>
                            </div>
                            <div className="row m-top-5 anim" style={{ transitionDelay: "500ms" }}>
                                <div className={currentLocation === '/B2B' ? 'main-nav-item active' : 'main-nav-item'} onClick={this.onNavItemClick.bind(this, "B2B")}>{t('nav.team_company', 'Team or Company')}</div>
                            </div>
                            <div className="sep m-top-30 anim" style={{ transitionDelay: "550ms" }}></div>
                        </div> */}
                        <div className="row m-top-30">
                            <div className="row m-top-5 anim" style={{ transitionDelay: "400ms" }}>
                                <div className='main-nav-item' onClick={this.onNewSearch.bind(this)}>{t('nav.new_search', 'Nova pesquisa')}</div>
                            </div>
                            <div className="sep m-top-30 anim" style={{ transitionDelay: "550ms" }}></div>
                        </div>
                        <div className="row m-top-30 anim" style={{ transitionDelay: "600ms" }}>
                            <a className={currentLocation === '/portfolio' ? 'main-nav-item active' : 'main-nav-item'} href="/portfolio" target="_self">{t('nav.your_list', 'Your list')} <span className="c-blue">({currentPortfolio ? currentPortfolio.length : 0})</span></a>
                        </div>
                        {/*
                            <div className="row m-top-30">
                                <a className={ currentLocation === '/about' ? 'main-nav-item active' : 'main-nav-item' } href="/about" target="_self">{t('nav.about', 'About')}</a>
                            </div>
                        */}
                    </div>
                    <Language />
                </nav>
                <PopupWarning show={popup} onClose={this.onClosePopup.bind(this)} />
            </div>
        )
    }
}

export default withRouter(withTranslation()(Navigation));