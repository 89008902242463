import React, { Component } from "react";
//import PDFViewer from 'pdf-viewer-reactjs';
import FileViewer from 'react-file-viewer';
import './Pdf.scss';
import { withTranslation  } from 'react-i18next';
 
class PdfPreview extends Component {
    onClose(){
        this.props.onClosePdf();
    }
    
    onError(e) {
        //logger.logError(e, 'error in file-viewer');
    }
    render() {
        const { t, file, added, solution, addToPortfolio, removeFromPortfolio, popupForm} = this.props;
        console.log(solution.types.target)
        //const file = this.props.file;
        const type = 'pdf';
        return(
            <div className="preview scrollbar">
                <div className="pdf-bg" onClick={this.onClose.bind(this)}></div>
                <div className="pdf-modal">
                    <div className="close-pdf">
                        <span className="icon-close" onClick={this.onClose.bind(this)}></span>
                        <div className="row flex jc-sb m-top-20 m-auto a-left">
                             {  
                                /*
                                <div className="f-s-12" style={{maxWidth: '60%'}}>{t( solution.types.target === "B2B" ? 'detail.preview_b2b' : 'detail.preview_b2c', 'You are previewing a solution. For more information add to the portfolio.')}</div>
                                */
                                added < 0 ? <div className="button small icon" onClick={addToPortfolio} style={{ backgroundColor: solution.primary_focus[0].color }}><span className="icon-book"></span><span>{t('btn.add', 'Add to your Portfolio')}</span></div>
                                : <div className="button small icon secundary" onClick={removeFromPortfolio} style={{color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }}><span className="icon-delete"></span><span>{t('btn.remove', 'Remove from your Portfolio')}</span></div>
                            }
                            {
                                 solution.has_landing_page ? <a className="button small secundary" href={solution.landing_page} target="_blank" style={{ borderColor: solution.primary_focus[0].color, color: solution.primary_focus[0].color }}><span>{t('btn.learn_more', 'Learn More')}</span></a>
                                 : <div className="button small secundary" onClick={popupForm} style={{color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }}><span>{t('btn.download', 'Download')}</span></div>
                            }
                        </div>
                    </div>
                    <div className="pdf-wrapper m-top-10">
                        <FileViewer
                        fileType={type}
                        filePath={file}
                        onError={this.onError}/>
                    </div>
                </div>
            </div>
        )    
    }
}

export default withTranslation()(PdfPreview);