import React, { Component } from "react";
import { ServicesContext } from '../../context/services';
import './IntervalEqualizer.scss';
import { Range, getTrackBackground } from 'react-range';
import './CheckboxCircle.scss';

import AreaChart from './AreaChart.js';

class IntervalEqualizer extends Component {
    constructor(props) {    
        super(props);
      
        const savedOptions = JSON.parse(localStorage.getItem('iter:filter:'+this.props.id));
      
        this.state = {
            filters: this.props.filters,
            originalOptions: this.props.options,
            values: savedOptions && savedOptions.length > 0 ? savedOptions : [this.props.options[0].value, this.props.options[this.props.options.length-1].value]
        };

        this.onRangeChange = this.onRangeChange.bind(this);

        this.updateComplete = 0;
    }

    reset(){
        const options = this.props.options;
        const option1 = options[0];
        const option2 = options[options.length-1];

        this.setState({values: [option1.value, option2.value] });
        
        localStorage.removeItem('iter:filter:'+this.props.id);
    }
    
    componentDidMount(){
        // this.updateValues();
    }

    getCurrentFilters(){
        const filterCriteria = JSON.parse(localStorage.getItem('iter:filtercriteria')) ? JSON.parse(localStorage.getItem('iter:filtercriteria')) : {};
        let filters = {}
        for (var key in filterCriteria) {
            let tmpKey = key;

            if(filterCriteria[key].length){
                switch (key) {
                    case 'types':
                        tmpKey = "types_some";
                        break;
                    case 'format':
                        tmpKey = "format_some";
                        break;
                    case 'scholarship':
                        tmpKey = "scholarship_some";
                        break;
                    case 'location':
                        tmpKey = "location_some";
                        break;
                    case 'language':
                        tmpKey = "languages_some";
                        break;
                    case 'sector':
                        tmpKey = "sector_some";
                        break;
                    default:
                        break;
                }
                if(key === "primary_focus" && filterCriteria[key].length){
                    filters['OR'] = [{'primary_focus_some': {'id_in': []} }, { 'secundary_focus_some': {'id_in': []} }]
                    for (let i in filterCriteria[key]) {
                        let criteria = filterCriteria[key][i].id;
                        filters.OR[0].primary_focus_some.id_in.push(criteria);
                        filters.OR[1].secundary_focus_some.id_in.push(criteria);
                    }
                }else if(key === "learning_methodology"){
                    if(this.props.id !== "learning_methodology") filters['methodology'] = { 'value_gte' : filterCriteria[key][0], 'value_lte' : filterCriteria[key][1] };
                }else if(key === "seniority"){
                    if(this.props.id !== "seniority") filters['AND'] = [{ 'min_seniority': { 'value_lte': filterCriteria[key][1] } },{ 'max_seniority': { 'value_gte': filterCriteria[key][0] } }]
                }else{
                    filters[tmpKey] = { 'id_in': []  }
                    for (let v in filterCriteria[key]) {
                        const criteria = filterCriteria[key][v].id;
                        filters[tmpKey].id_in.push(criteria);
                    }
                }
            }
        }
        
        const filtersString = JSON.stringify(filters);  
        let filtersGraphQL = filtersString.replace(/"([^(")"]+)":/g,"$1:");
        filtersGraphQL = filtersGraphQL.slice(0, -1);
        filtersGraphQL += ', state: published }';
        return filtersGraphQL;
    }

    getNewValues(){
        this.updateValues.bind(this);
    }

    updateValues(){
        let newOptions = [...this.state.originalOptions];
        let currentFilter = this.getCurrentFilters();
        currentFilter = currentFilter.slice(1,-1);
        this.updateComplete = 0;
        this.props.options.forEach((option, index) => {
            if(this.props.id === "seniority") this.getSeniorityLength(newOptions, option.value, currentFilter);
            //if(index === this.props.options.length-1 && this.props.id === "seniority") this.getMaxSeniorityLength(newOptions, this.props.options[this.props.options.length-1].id, currentFilter);
            if(this.props.id === "learning_methodology") this.getMethodologyLength(newOptions, option.id, currentFilter)
        });
    }

    getMethodologyLength(newOptions, optionId, currentFilter){
        const { services } = this.context;
        services.solutionsApi.getMethodologyLength( optionId, currentFilter)
        .then(({ _allSolutionsMeta }) => {
            let obj = newOptions.find(o => o.id === optionId);
            obj.len = _allSolutionsMeta.count;
            //console.log(this.props.id, obj.value, obj.len);
            this.updateComplete++;
            if(newOptions.length === this.updateComplete) this.setState({originalOptions : newOptions});
        }).catch(error => console.log(error));
    }

    getSeniorityLength(newOptions, value, currentFilter){
        const { services } = this.context;
        services.solutionsApi.getMinSeniorityLength( value, currentFilter)
        .then(({ _allSolutionsMeta }) => {
            let obj = newOptions.find(o => o.value === value);
            obj.len = _allSolutionsMeta.count;
            //console.log(this.props.id, obj.value, obj.len);
            this.updateComplete++;
            if(newOptions.length === this.updateComplete) this.setState({originalOptions : newOptions});
        }).catch(error => console.log(error));
    }

    getMaxSeniorityLength(newOptions, optionId, currentFilter){
        const { services } = this.context;
        
        services.solutionsApi.getMaxSeniorityLength( optionId, currentFilter)
        .then(({ _allSolutionsMeta }) => {
            let obj = newOptions.find(o => o.id === optionId);
            obj.len = _allSolutionsMeta.count;
            //console.log(this.props.id, obj.value, obj.len);
            this.updateComplete++;
            if(newOptions.length === this.updateComplete) this.setState({originalOptions : newOptions});
        }).catch(error => console.log(error));
    }
    
    onRangeChange(value){
        const { id, onFilterChange } = this.props;
        onFilterChange( id, value );
        localStorage.setItem('iter:filter:'+this.props.id,JSON.stringify(value));
    }

    getOption(value){
        let option = this.props.options.filter(option => {
            return option.value === value;
        });

        return option[0];
    }

    render() {
        const { values, originalOptions } = this.state;
        //console.log('values', values);
        //console.log('originalOptions', originalOptions);

        //validate if info is complete
        let chartArray = [],
            chartInfoCompleted = 0;
        for (let i = 0; i < originalOptions.length; i++) {
            let option = originalOptions[i];
            if(option.len){
                chartArray[i] = option.len;
                chartInfoCompleted++;
            } 
        }

        //build graph array with x and y pos
        const min = values[0];
        const max = values[1];

        let originalChart = [0, 0, 0, 0, 0, 0];
        let newChart = [0, 0, 0, 0, 0, 0];
        if(this.updateComplete === originalOptions.length){
            originalChart =[];
            newChart = [];
            const chartWidth = 220/originalOptions.length;
            for (let i = 0; i < originalOptions.length; i++) {
                let option = originalOptions[i];
                originalChart.push({x: chartWidth * i, y: option.len});
                if (option.value === min) newChart.push({ x: chartWidth * i, y: 0 }, { x: chartWidth * i, y: option.len });
                else if(option.value > min && option.value < max) newChart.push({ x: chartWidth * i, y: option.len });
                else if(option.value === max) newChart.push({ x: chartWidth * i, y: option.len }, { x: chartWidth * i, y: 0 } );
                else newChart.push({ x: chartWidth * i, y: "" });
            }
        }

        //set labels
        const leftLabel = this.props.id === "seniority" ? this.getOption(values[0])[this.props.lang] : this.props.options[0][this.props.lang]; 
        const rightLabel = this.props.id === "seniority" ? this.getOption(values[1])[this.props.lang] : this.props.options[this.props.options.length-1][this.props.lang]; 
        return (
             <div className="row m-top-35">
                <h4 className="f-f-flayfair f-bold f-s-14">{this.props.title}</h4>
                <div style={{minHeight: '50px', 'marginLeft': -10, 'marginTop': -10,'marginBottom': -20, 'overflow': 'hidden'}}>
                    { originalOptions.length && this.updateComplete === originalOptions.length ? <AreaChart chartArray={originalChart} overChart={newChart} />  : null }
                </div>
                <div className="row flex pad-10"
                    style={{
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                    }}
                >
                    <Range
                    values={this.state.values}
                    step={1}
                    min={this.props.options[0].value}
                    max={this.props.options[this.props.options.length-1].value}
                    onChange={values => {
                        this.setState({ values })
                    }}
                    onFinalChange={values => {
                        this.onRangeChange(values);
                    }}
                    renderTrack={({ props, children }) => (
                        <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '100%'
                        }}
                        >
                        <div
                            ref={props.ref}
                            style={{
                            height: '4px',
                            width: '100%',
                            borderRadius: '4px',
                            background: getTrackBackground({
                                values: this.state.values,
                                colors: ['#A9C2DE', '#fff', '#A9C2DE'],
                                min: this.props.options[0].value,
                                max: this.props.options[this.props.options.length-1].value
                            }),
                            alignSelf: 'center'
                            }}
                        >
                            {children}
                        </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '20px',
                            width: '20px',
                            borderRadius: '50%',
                            border: '2px solid #fff',
                            backgroundColor: '#009BD0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                        <div
                            style={{
                            height: '10px',
                            width: '2px',
                            backgroundColor: isDragged ? '#fff' : '#fff'
                            }}
                        />
                        </div>
                    )}
                    />
                    <div className="row flex ai-top jc-sb f-s-10" style={{minHeight: 30}}>
                        <span className="w-45">{leftLabel}</span>
                        <span className="w-45 a-right">{rightLabel}</span>
                    </div>
                </div>
            </div>
        )
    }
}
IntervalEqualizer.contextType = ServicesContext;

export default IntervalEqualizer;