import React, { Component } from "react";
import { withTranslation  } from 'react-i18next';
import './Language.scss';

class Language extends Component {
    constructor(props) {    
        super(props);
        this.changeLang = this.changeLang.bind(this);
    }
    
    changeLang(e) {
        e.preventDefault();
        const lng = e.currentTarget.getAttribute('data-lang');
        const { i18n } = this.props;
        i18n.changeLanguage(lng);
    }

    render() {
        const { i18n } = this.props;
        return (
            <div className="lang">
                {
                    <div className="row">
                        <div className="lang-options">
                            <div onClick={this.changeLang} data-lang="pt" className={i18n.language === "pt" ? "active" : ""}>PT</div>
                            <div onClick={this.changeLang} data-lang="en" className={i18n.language === "en" ? "active" : ""}>EN</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(Language);