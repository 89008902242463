//import axios from 'axios';

import initApollo, { makeQuery, makeMutation } from '../lib/apollo';

import makeSolutionsApi from './solutions';
import makeFiltersApi from './filters';
import makeLeadsApi from './leads';
import makeCampaignsApi from './campaigns';

const apolloClient = initApollo();
const query = makeQuery(apolloClient);
const mutation = makeMutation(apolloClient);
/*
const request = (url, options) => axios
	.request(Object.assign(options || {}, { url: process.env.REACT_APP_WEBSITE_NAME + '/gateway/' + url }))
	.then(response => (response && response.data) || response);
*/
const solutionsApi = makeSolutionsApi({ query, mutation });
const filtersApi = makeFiltersApi({ query, mutation });
const leadsApi = makeLeadsApi({ query, mutation });
const campaignsApi = makeCampaignsApi({ query, mutation });

const services = {
    solutionsApi, filtersApi, leadsApi, campaignsApi
};

export default services;

export {
    solutionsApi, filtersApi, leadsApi, campaignsApi
};