import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { ServicesContext } from '../context/services';
import Helmet from 'react-helmet';
import './../App.scss';
import './Detail.scss';
import Nav from '../components/Navigation';
import Loading from '../components/ui/Loading';
import DetailCard from '../components/shared/DetailCard';
import SpeakerCard from '../components/shared/SpeakerCard';
import Graph from '../components/Graph';
import Speaker from "../components/shared/Speaker";

class Detail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            relatedSolutions: null,
            currentSolution: null,
            currentPortfolio: JSON.parse(localStorage.getItem('iter:portfolio') ? localStorage.getItem('iter:portfolio') : "[]")
        }

        this.onCardChange = this.onCardChange.bind(this);
    }

    getDetail() {
        const { services } = this.context;
        const slug = this.props.match.params.slug;
        console.log(slug)
        services.solutionsApi.getSolutionDetailBySlug(slug)
            .then(({ allSolutions }) => {
                if(allSolutions){
                    if (allSolutions) this.setState({ currentSolution: allSolutions[0] })

                    let primary_focus_ids = []
                    for (let i = 0; i < allSolutions[0].primary_focus.length; i++) {
                        const primary_focus = allSolutions[0].primary_focus[i];
                        primary_focus_ids.push(primary_focus.id);
                    }
                    //this.getRelatedSolutions(allSolutions[0].id, allSolutions[0].primary_focus, allSolutions[0].types.id)
                    this.getRelatedSolutions(allSolutions[0].id, JSON.stringify(primary_focus_ids), allSolutions[0].types.id)
                }
            }).catch(error => {
                console.log('error', error)
                //window.location = "/"
            });
    }

    //Focus Primário – Se entre 3 e 10 (ficam esses), se for superior a 10, entra o focus secundário. 
    //Se aumentar acima de 20 entra filtro de senioridade similar à pesquisa original realizada. 
    //Se ficar entre 3 e 10, fica aí. Se for superior, entra o filtro tipo (online, blended, presencial)
    getRelatedSolutions(current, primary_focus, types) {
        console.log('primary_focus', primary_focus)
        const { services } = this.context;

        this.setState({ loading: true });
        services.solutionsApi.getRelatedSolutions(current, primary_focus, types)
            .then(({ allSolutions }) => {
                if (allSolutions.length > 10 || allSolutions.length < 3) this.getFilteredBySecundaryFocus(current, primary_focus, types);
                else this.setState({ relatedSolutions: allSolutions, loading: false });
            }).catch(error => console.log(error));
    }

    getFilteredBySecundaryFocus(current, primary_focus, types) {
        const { services } = this.context;

        const secundaryFilter = this.state.currentSolution.secundary_focus;
        let secundaryOptions = { 'id_in': [] };

        secundaryFilter.forEach(secundary => {
            secundaryOptions.id_in.push(secundary.id);
        });

        const secundary_focus = JSON.stringify(secundaryOptions).replace(/"([^(")"]+)":/g, "$1:");

        services.solutionsApi.getRelatedFocusSecundarySolutions(current, primary_focus, types, secundary_focus)
            .then(({ allSolutions }) => {
                if (allSolutions.length > 3 && allSolutions.length < 10) this.setState({ relatedSolutions: allSolutions, loading: false });
                else this.getFilteredBySenioritySolutions(current, primary_focus, types, secundary_focus);
            }).catch(error => console.log(error));

    }

    getFilteredBySenioritySolutions(current, primary_focus, types, secundary_focus) {
        const { services } = this.context;

        const min_seniority = this.state.currentSolution.min_seniority.value,
            max_seniority = this.state.currentSolution.max_seniority.value;

        services.solutionsApi.getRelatedSenioritySolutions(current, primary_focus, types, secundary_focus, min_seniority, max_seniority)
            .then(({ allSolutions }) => {
                console.log('Related level 3 - Filter by Seniority', allSolutions);
                if (allSolutions.length > 3 && allSolutions.length < 10) this.setState({ relatedSolutions: allSolutions, loading: false });
                else this.getFilteredByFormat(current, primary_focus, types, secundary_focus, min_seniority, max_seniority);
            }).catch(error => console.log(error));
    }

    getFilteredByFormat(current, primary_focus, types, secundary_focus, min_seniority, max_seniority) {
        const { services } = this.context;

        const formatFilter = this.state.currentSolution.format;
        let formatOptions = { 'id_in': [] };

        formatFilter.forEach(format => {
            formatOptions.id_in.push(format.id);
        });

        const format = JSON.stringify(formatOptions).replace(/"([^(")"]+)":/g, "$1:");

        services.solutionsApi.getRelatedFormatSolutions(current, primary_focus, types, secundary_focus, min_seniority, max_seniority, format)
            .then(({ allSolutions }) => {
                console.log('Related level 4 - Filter by Format', allSolutions);
                this.setState({ relatedSolutions: allSolutions.slice(0, 15), loading: false });
            }).catch(error => console.log(error));

    }

    componentWillMount() {
        this.getDetail();
    }

    onCardChange() {
        const currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio'));
        this.setState({ currentPortfolio: currentPortfolio });
    }

    getDetailURL(program) {
        let url = '/';
        const slug = this.props.i18n.language === 'pt' ? program.url_pt : program.url_en;
        url += 'detail/' + slug;
        return url;
    }

    render() {
        const { currentSolution, currentPortfolio, relatedSolutions } = this.state;
        const { t, i18n } = this.props;
        let sum;
        if (currentSolution) {
            sum = i18n.language === "pt" ? currentSolution.summary_pt : currentSolution.summary_en;

            const solutionURL = this.getDetailURL(currentSolution);
            if (window.location.pathname !== solutionURL) {
                window.history.pushState({}, null, solutionURL);
            }
        }
        //const added = currentPortfolio ? currentPortfolio.indexOf(this.props.match.params.id) : -1;
        if (!currentSolution) return null; //try to fix SEO

        return (
            <div className="App bg detail">
                {currentSolution ? <Helmet title={'Iter by Nova SBE Executive Education - ' + currentSolution[i18n.language]} /> : <Helmet title="Iter by Nova SBE Executive Education - Loading..." />}
                <div className="main ">
                    <Nav portfolio={currentPortfolio} backUrl={currentSolution ? "/" + currentSolution.types.target : null} />
                    <div className="wrapper">
                        {currentSolution ?
                            <>
                                {
                                    currentSolution.type.en === 'Speaker' && currentSolution.speaker_name || currentSolution.type.en === 'Keynote' ?
                                    <SpeakerCard solution={currentSolution} onChange={this.onCardChange} />
                                    :
                                    <DetailCard solution={currentSolution} onChange={this.onCardChange} />
                                }
                                {relatedSolutions ?
                                    <div className="detail-graph">
                                        <div className="graph-info">
                                            <div className="row max-200">
                                                <p className="f-s-14 c-blue">{t('dashboard.subtitle', '')}</p>
                                                <p className="f-f-flayfair f-bold f-s-22 c-darkblue m-top-10">{t('detail.continue_explore', '')}</p>
                                            </div>
                                            <div className="row related">
                                                <span className="row f-f-flayfair f-bold f-s-44 c-blue">{relatedSolutions.length}</span>
                                                <div className="m-top-5">
                                                    <span className="f-s-14">{t('detail.related_offers', 'Results')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Graph solutions={relatedSolutions} width="1500" height="900" start="150" anchor="1000" />
                                        </div>
                                    </div>
                                    :
                                    <div className="detail-graph">
                                        <div className="graph-info">
                                            <div className="row max-200">
                                                <p className="f-s-14 c-blue">{t('dashboard.subtitle', '')}</p>
                                                <p className="f-f-flayfair f-bold f-s-22 c-darkblue m-top-10">{t('detail.continue_explore', '')}</p>
                                            </div>
                                            <Loading />
                                        </div>
                                    </div>
                                }
                            </>
                            : <Loading />
                        }
                    </div>
                </div>
                {currentSolution ?
                    <script type='application/ld+json' dangerouslySetInnerHTML={{
                        __html:
                            `{ 
                        "@context": "https://schema.org",
                        "@type": "Course",
                        "name": "`+ currentSolution[i18n.language] + `",
                        "description": "`+ sum + `",
                        "provider": {
                        "@type": "Organization",
                        "name": "Nova School of Business and Economics",
                        "sameAs": "https://iter.exed.novasbe.unl.pt"
                        }
                    }`
                    }} />
                    : null}
            </div>
        )
    }
}

Detail.contextType = ServicesContext;

export default withTranslation()(Detail);
