import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import './Graph.scss';
import MediaQuery from 'react-responsive'

class Graph extends Component {
    constructor(props) {
        super(props);

        this.state = { index: 0 };

        this.update = this.update.bind(this);
        this.updateIndex = this.updateIndex.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    updateIndex(solution, alpha, y, show, label, selected) {
        if (!solution) return;

        solution.y = y;
        solution.transparent = !alpha;
        solution.color = (solution.primary_focus[0] ? solution.primary_focus[0].color : "black");
        solution.class = selected ? "selected" : (label ? "active" : "");
        solution.selected = selected;
        solution.show = show;
    }

    update(solutions) {
        let y, a, i, step;

        const h = 1, h2 = h / 2;
        const max = solutions.length;
        const index = this.state.index;
        const offset = 0;

        if (max === 0) return [];

        if (max >= 10) step = Math.PI / 11;
        else step = Math.PI / (max + 2);

        //move the results up
        for (a = 0, i = index - 1; i >= 0 && i > index - 5; i--) {
            a += step;
            y = h2 - offset - (Math.sin(a) * h2);
            this.updateIndex(solutions[i], true, y, true, i > index - 4);
        }

        //hide the results up
        for (; i >= 0 && i >= index - 20; i--) this.updateIndex(solutions[i], false, 0, true);
        for (; i >= 0; i--) this.updateIndex(solutions[i], false, 0, false);

        //show the highlighted result
        this.updateIndex(solutions[index], true, h2, true, true, true);

        //move the results down
        for (a = 0, i = index + 1; i < max && i < index + 5; i++) {
            a += step;
            y = h2 + offset + (Math.sin(a) * h2);
            this.updateIndex(solutions[i], true, y, true, i < index + 4);
        }

        //hide the results down
        for (; i < max && i < index + 10; i++) this.updateIndex(solutions[i], false, h, true);
        for (; i < max; i++) this.updateIndex(solutions[i], false, h, false);

        return solutions;
    }

    handleScroll(e) {
        e.preventDefault();

        const { solutions } = this.props;
        const scrollH = solutions.length * e.target.offsetHeight;
        const offset = e.target.scrollTop / (scrollH - e.target.offsetHeight);

        this.setState({ index: Math.floor(offset * solutions.length) });
    }

    setHeight() {
        const graphEl = document.getElementById("result");
        const height = window.innerHeight - graphEl.offsetTop;
        graphEl.style.height = height + "px";
    }

    componentDidMount() {
        if (window.location.pathname.includes("/B2") && window.innerWidth < 767) this.setHeight();
    }

    getDetailURL(program) {
        let url = '/';
        const slug = this.props.i18n.language === 'pt' ? program.url_pt : program.url_en;
        url += 'detail/' + slug;
        //url += program.id;
        return url;
    }

    render() {
        const { solutions, width, height, start, anchor } = this.props;
        const programs = this.update(solutions);
        const scrollH = programs.length * 100;
        //const { isScrolling } = this.props;
        const centerH = height / 2;

        const getSolutionName = solution => {
            //return solution.type.en === 'Speaker' ? (solution.speaker_name ? solution.speaker_name : solution[this.props.i18n.language]) : solution[this.props.i18n.language];
            return solution[this.props.i18n.language];
        }

        return (
            <MediaQuery minWidth={768}>
                {(matches) =>
                    matches
                        ?
                        <div className={solutions && solutions.length !== 0 ? 'graph' : 'graph no-results'} id="result">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
                                {programs.map((program, n) => (
                                    program.show ? <path key={n} fill="transparent" d={`M0 ${centerH} H ${start} M${start} ${centerH} C${height} ${centerH} ${centerH} ${(program.y * height)} ${anchor} ${(program.y * height)} H ${width}`} stroke={program.transparent ? "transparent" : program.color} strokeWidth="3"></path> : null
                                ))}
                            </svg>
                            <ul>
                                {programs.map((program, n) => (
                                    program.show ?
                                        <li key={n} style={{ top: (program.y * 100) + '%', color: program.color }} className={program.class}>
                                            {program.selected ?
                                                <a href={this.getDetailURL(program)} target="_self">
                                                    <div className="initials" style={{ backgroundColor: program.color ? program.color : "#000" }}>{program.initials ? program.initials : ''}</div>
                                                    <div className="detail">
                                                        <span className="cat">{program.experience ? program.experience[this.props.i18n.language] : ''}</span>
                                                        <span className="name">{getSolutionName(program)}</span>
                                                    </div>
                                                </a>
                                                :
                                                <a href={this.getDetailURL(program)} target="_self">
                                                    <div className="detail">
                                                        <span className="name">{getSolutionName(program)}</span>
                                                    </div>
                                                </a>
                                            }
                                        </li> : null
                                ))}
                            </ul>
                            {
                                solutions && solutions.length ?
                                    <>
                                        <div className="bg-scroll">
                                            <div className="icon-arrow-up"></div>
                                            <div className="icon-arrow-down"></div>
                                        </div>

                                        <div className="graph-scroll scrollbar" onScroll={this.handleScroll}>
                                            <div className="content" style={{ height: scrollH + '%' }}></div>
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </div>
                        :
                        <div className={solutions && solutions.length !== 0 ? 'graph' : 'graph no-results'} id="result">
                            <ul>
                                {programs.map((program, n) => (
                                    <li key={n} style={{ color: program.color ? program.color : "#000" }} className="selected">
                                        <a href={this.getDetailURL(program)} target="_self">
                                            <div className="initials" style={{ backgroundColor: program.color }}>{program.initials ? program.initials : ''}</div>
                                            <div className="detail">
                                                <span className="cat">{program.experience ? program.experience[this.props.i18n.language] : ''}</span>
                                                <span className="name">{program[this.props.i18n.language]}</span>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                }
            </MediaQuery>
        )
    }
}
export default withTranslation()(Graph);