import React, { Component } from "react";
import './Intro.scss';

class Intro extends Component {

    render() {
        return (
            <div className='intro'>
                <div class='content'>
                    <div class='row'>
                        <img src='/images/logo.svg' alt='Iter'/>
                    </div>
                    <div class='row m-top-20'>
                        <img src='/images/loading.gif' alt='Loading'/>
                    </div>
                    <div class='row m-top-10'>
                        <span className='f-p-14'>An iter.active experience</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Intro;