import React, { Component } from "react";
import { withTranslation  } from 'react-i18next';
import { ServicesContext } from '../context/services';
import TextAnim from 'rc-texty';
import './Home.scss';
//import Loading from '../components/Loading';
import Header from '../components/Header';
import PopupWarning from '../components/shared/PopupWarning';
import Graph from '../components/ui/AnimatedGraph';
import MediaQuery  from 'react-responsive'
import { Link } from "react-router-dom";
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';

class Home extends Component {
    constructor(props) {    
      super(props);

        const version = localStorage.getItem('iter:version');
        console.log('version', version);
        if(!version || version !== '1.5.1') {
            localStorage.clear();
            localStorage.setItem('iter:version', '1.5.1');
        }


      this.state = {
          userTypes: null,
          popup: false,
          animate: false,
          video: JSON.parse(localStorage.getItem('iter:home_video')) ? false : true,
      }
    }

    // getAllUserTypes() {
    //     const { services } = this.context;
        
    //     services.filtersApi.getAllUserTypes()
    //     .then(({ allUserTypes }) => {
    //         if(allUserTypes) this.setState({userTypes: allUserTypes});
    //     }).catch(error => console.log(error));
    // }

    componentDidMount(){
        //this.getAllUserTypes();
        this.setState({animate: true})
    }
    
    onNavItemClick(type, e){
        e.preventDefault();
        const currentUserType = localStorage.getItem('iter:type');
        currentUserType && type !== currentUserType ? this.setState({ popup: true }) : window.location = "/" + type;
    }

    onClosePopup(){
        //close popup and navigation
        this.setState({ popup: false, open: false });
    }

    openVideo() {
        this.setState({ video: true })
    }

    closeVideo() {
        this.setState({ video: false })
        localStorage.setItem('iter:home_video', true);
    }

    render() {
        const { t, i18n } = this.props;
        const { popup, animate, video } = this.state;

        return (
            <div className="App bg home">
                <div className="main flex">
                    <Header page="home" lang={true}/>
                    <div className="wrapper">
                        <div className={!animate ? "content anim-init" : "content anim-init anim-start" } style={{transitionDelay: "0ms"}}>
                            <MediaQuery minWidth={1300}>
                                <Graph width="465" height="465" />
                            </MediaQuery>
                            
                            <MediaQuery minWidth={1115} maxWidth={1299}>
                                <Graph width="400" height="400" />
                            </MediaQuery>
                            <MediaQuery minWidth={975} maxWidth={1114}>
                                <Graph width="350" height="350" />
                            </MediaQuery>
                            <MediaQuery minWidth={0} maxWidth={974}>
                                <img src="/images/home-map.png" alt="Map" />
                            </MediaQuery>
                        </div>
                        <aside className="aside aside-1">
                            <div>
                                { i18n.language === "pt" ?
                                    <h1 className="f-f-flayfair f-italic f-s-44 c-blue">
                                        <TextAnim interval={12} delay={750} mode="smooth">{t('home.txt1', 'Learning is a transformative journey.')}</TextAnim>
                                    </h1>
                                : 
                                    <h1 className="f-f-flayfair f-italic f-s-44 c-blue">
                                        {t('home.txt1', 'Learning is a transformative journey.')}
                                    </h1>
                                }
                                <div className="row m-top-25">
                                    { i18n.language === "pt" ?
                                        <span className="f-s-16 c-darkblue"><TextAnim interval={10} delay={1000} >{t('home.txt2', 'One that pushes people and organizations beyond their own horizons.')}</TextAnim></span>
                                    : 
                                        <span className="f-s-16 c-darkblue">{t('home.txt2', 'One that pushes people and organizations beyond their own horizons.')}</span>
                                    }
                                </div>
                            </div>
                        </aside>
                        <aside className="aside aside-2">
                            <div className={!animate ? "welcome" : "welcome anim" } style={{transitionDelay: "2700ms"}}>
                                { i18n.language === "pt" ?
                                    <div className="row max-200">
                                        <div className="f-f-flayfair f-bold f-s-18 c-darkblue">
                                            <TextAnim interval={10} delay={1100}>{t('home.title', 'Welcome to Iter.')}</TextAnim> 
                                            <span className={!animate ? "icon-info m-left-5 anim-init" : "icon-info m-left-5 anim-init anim-start" } style={{transitionDelay: "1700ms"}}> 
                                                <div className="tooltip">
                                                    <div className="row">
                                                        <span className="f-s-12 c-darkblue">{t('home.tooltip')}</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                        <div className="f-f-flayfair f-s-18 c-darkblue"><TextAnim interval={10} delay={1300} >{t('home.subtitle', 'An iter.active experience.')}</TextAnim></div>
                                    </div>
                                : 
                                    <div className="row max-200">
                                        <div className="f-f-flayfair f-bold f-s-18 c-darkblue">
                                            {t('home.title', 'Welcome to Iter.')} 
                                            <span className={!animate ? "icon-info m-left-5 anim-init" : "icon-info m-left-5 anim-init anim-start" } style={{transitionDelay: "1700ms"}}> 
                                                <div className="tooltip">
                                                    <div className="row">
                                                        <span className="f-s-12 c-darkblue">{t('home.tooltip')}</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                        <p className="f-f-flayfair f-s-18 c-darkblue">{t('home.subtitle', 'An iter.active experience.')}</p>
                                    </div>
                                }
                                
                            </div>
                            <div className="type">
                                {/* <div className="row">
                                { i18n.language === "pt" ?
                                    <span className="f-f-flayfair f-bold f-s-18 c-darkblue"><TextAnim interval={10} delay={1700} >{t('home.question', 'What type of journey are you looking for?')}</TextAnim></span>
                                :
                                    <span className="f-f-flayfair f-bold f-s-18 c-darkblue">{t('home.question', 'What type of journey are you looking for?')}</span>
                                }
                                </div> */}
                                <div className={!animate ? "row anim-init" : "row anim-init anim-start" } style={{transitionDelay: "1800ms"}}>
                                    <div className="row">
                                        <Link to={i18n.language === "pt" ? "/jornada" : "/journey"} className="button icon bg-darkblue">
                                            <div style={{display: 'flex'}}>
                                                <span className="icon-arrow-intro"></span>
                                                <span>{t('btn.start_journey')}</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="row m-top-10">
                                        <a className="button icon" onClick={this.openVideo.bind(this)}> 
                                            <div style={{display: 'flex'}}>
                                                <span className="icon-play"></span>
                                                <span>{t('btn.watch_video', 'Ver vídeo')}</span>
                                            </div>
                                        </a>
                                    </div>
                                    
                                    {/* userTypes ? 
                                        userTypes.map(item => (
                                            <div className="row m-top-10" key={item.id}>
                                                <Link to={"/" + item.target.toLowerCase()} onClick={this.onNavItemClick.bind(this, item.target)} className="button icon">
                                                    <div><span className={item.target === "B2C" ? "icon-particular" : "icon-empresa"}></span><span>{item.target === "B2C" ? t('home.individual') : t('home.company')}</span></div>
                                                </Link>
                                            </div>
                                        ))
                                    :
                                        <div className="row">
                                            <Loading />
                                        </div>
                                    */}
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
                <PopupWarning show={popup} onClose={this.onClosePopup.bind(this)}/>
                {video ? <ModalVideo channel='youtube' isOpen={video} videoId="0Yl-FLDpkP0" onClose={this.closeVideo.bind(this)} /> : null}
            </div>
        )
    }
}

Home.contextType = ServicesContext;
export default withTranslation()(Home); 