export default function makeLeadsApi({
	query,
	mutation,
}) {
	const userFragment = `{
            id,
            name
        }
	`;
	return {
        add: data => mutation(`
            mutation {
                createLead(data: { ${data} }) { id }
            }
        `),
	};
}