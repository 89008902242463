import React, { Component } from "react";
import { ServicesContext } from '../context/services';
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Sidebar from '../components/Sidebar';
import Nav from '../components/Navigation';
import TagList from '../components/ui/tags/TagList';
import Loading from '../components/ui/Loading';
import Graph from '../components/Graph';
import './../App.scss';
import './Dashboard.scss';
import PopupSearch from '../components/shared/PopupSearch';
import queryString from 'query-string';

import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import { isStepDivisible } from "react-range/lib/utils";


const savedJourneys = JSON.parse(localStorage.getItem('iter:journey'));

class Dashboard extends Component {
    constructor(props) {
        super(props);

        let type = "[]";

        try {
            type = JSON.parse(localStorage.getItem('iter:type'));
        } catch (error) {
            console.log(error);
            localStorage.clear();
            localStorage.setItem('iter:type', "[]");
        }

        this.state = {
            type: type,
            sidebar: false,
            filterCriteria: JSON.parse(localStorage.getItem('iter:filtercriteria')) || {},
            loading: false,
            programs: savedJourneys ? savedJourneys : [],
            filters: null,
            currentPortfolio: JSON.parse(localStorage.getItem('iter:portfolio')) || [],
            addedPortfolio: false,
            animate: false,
            search: false
        };

        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.onSidebarChange = this.onSidebarChange.bind(this);
        this.onTagsChange = this.onTagsChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.onResetFilters = this.onResetFilters.bind(this);

        this.addResultsToPortfolio = this.addResultsToPortfolio.bind(this);

        this.listTagFilter = React.createRef();
        this.sidebarRef = React.createRef();
    }

    parseFilter(filter) {
        try {
            return JSON.parse(filter);
        } catch (e) {
            return {};
        }
    }

    componentDidMount() {
        const { type } = this.state;
        const query = queryString.parse(this.props.location.search);

        //if (type) localStorage.setItem('iter:type', type);

        let hasFilter = localStorage.getItem('iter:filter') || {};
        const sidebar = JSON.parse(localStorage.getItem('iter:sidebar'));

        if (sidebar !== null && window.innerWidth > 1024) this.setState({ sidebar: sidebar });

        if (Object.keys(query).length) {
            let filter = this.parseFilter(hasFilter), id;
            for (var key in query) {
                switch (key) {
                    case 'experience':
                        id = [query[key]];
                        filter.experience = {
                            id_in: id
                        }
                        const options = localStorage.getItem('iter:filter:experience');
                        const savedOptions = JSON.parse(options) || { id: id };

                        for (var i = 0; i < savedOptions.length; i++) {
                            if (savedOptions[i].id === id) {
                                savedOptions[i].checked = true;
                                break;
                            }
                        }

                        localStorage.setItem('iter:filter:experience', JSON.stringify(savedOptions));

                        break;
                }
            }

            hasFilter = JSON.stringify(filter);
        }

        if (hasFilter && Object.keys(hasFilter).length) {
            this.getSolutionsFiltered(hasFilter, sidebar === null ? true : false);
        } else this.getAllSolutions(sidebar === null ? true : false);

        //todo
        setTimeout(() => {
            this.setState({ animate: true })
        }, 0);
    }


    getAllSolutions(openSidebar) {
        const { services } = this.context;

        this.setState({ loading: true, programs: [] });

        services.solutionsApi.getAll()
            .then(({ allSolutions }) => {
                if (openSidebar) this.setState({ sidebar: window.innerWidth > 1024 ? true : false });
                this.setState({ programs: allSolutions, loading: false, filterCriteria: {}, addedPortfolio: false });
                // this.resetScrollbar();
            }).catch(error => { console.log(error); });
    }

    getSolutionsFiltered(filter, openSidebar = false) {
        const { services } = this.context;

        this.setState({ loading: true, programs: [], addedPortfolio: false });

        let filtersGraphQL = filter.replace(/"([^(")"]+)":/g, "$1:");
        filtersGraphQL = filtersGraphQL.slice(0, -1);
        filtersGraphQL += ', state: published }';

        services.solutionsApi.getSolutionFiltered(filtersGraphQL)
            .then(({ allSolutions }) => {
                if (openSidebar) this.setState({ sidebar: window.innerWidth > 1024 ? true : false });
                localStorage.setItem("iter:journey", JSON.stringify(allSolutions));
                this.setState({ programs: this.sortByFocus(allSolutions), loading: false, addedPortfolio: false });
                // this.resetScrollbar();
            }).catch(error => console.log(error));
    }

    getTags() {
        if (!this.listTagFilter) return [];
        if (!this.listTagFilter.current) return [];
        if (!this.listTagFilter.current.state) return [];
        if (!this.listTagFilter.current.state.options) return [];

        return this.listTagFilter.current.state.options;
    }

    filterItems(solutions, tags, result) {
        let item, tag, i, k;
        let left = [];
        if (tags) {
            for (i = 0; i < tags.length; i++) {
                tag = tags[i];
                for (k = 0; k < solutions.length; k++) {
                    item = solutions[k];
                    if (item.primary_focus) {
                        if (item.primary_focus[0].id === tag) result.push(item);
                        else left.push(item);
                    }
                }

                solutions = left.slice(); left = [];
            }
        } else {
            result = solutions;
        }

        return result;
    }

    sortByFocus(solutions) {
        const tags = this.getTags();

        let i, tag;
        let primary = [];
        let secundary = [];

        for (i = 0; i < tags.length; i++) {
            tag = tags[i];
            if (tag.checked) {
                for (let v = 0; v < tag.ids.length; v++) {
                    const item = tag.ids[v];
                    primary.push(item);
                }
            } else {
                for (let q = 0; q < tag.ids.length; q++) {
                    const item = tag.ids[q];
                    secundary.push(item);
                }
            }
        }

        if (this.props.i18n.language === "pt") {
            solutions.sort((a, b) => a.pt !== b.pt ? a.pt < b.pt ? -1 : 1 : 0);
        } else {
            solutions.sort((a, b) => a.en !== b.en ? a.en < b.en ? -1 : 1 : 0);
        }

        let ordered = [];

        this.filterItems(solutions, primary, ordered);
        this.filterItems(solutions, secundary, ordered);

        return ordered;
    }

    resetScrollbar() {
        const elemScroll = document.getElementsByClassName("scrollbar");
        let i;
        for (i = 0; i < elemScroll.length; i++) {
            elemScroll[i].scrollTop = 0;
        }
    }

    toggleSidebar(e) {
        e.preventDefault();
        const { sidebar } = this.state;
        this.setState({ sidebar: !sidebar });

        localStorage.setItem('iter:sidebar', !sidebar);
    }

    onSidebarChange(filterKey, filters) {
        var filterCriteria = this.state.filterCriteria;
        filterCriteria[filterKey] = filters;
        this.filterPrograms();
        if (filterKey === 'types') {
            this.setState({ type: filters });
            localStorage.setItem('iter:type', JSON.stringify(filters));
        }
    }

    onTagsChange(filterKey, filters) {
        var filterCriteria = this.state.filterCriteria;
        filterCriteria[filterKey] = filters;
        this.filterPrograms();
        if (filterKey === "primary_focus") {
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];
                if (filter.note && this.sidebarRef.current) this.sidebarRef.current.sectorValidation(true);
                else this.sidebarRef.current.sectorValidation(false);
            }
        }
    }

    filterPrograms() {
        const { filterCriteria } = this.state;
        let filters = {};

        localStorage.setItem("iter:filtercriteria", JSON.stringify(filterCriteria));

        for (var key in filterCriteria) {
            let tmpKey = key;

            if (filterCriteria[key].length) {
                switch (key) {
                    case 'types':
                        tmpKey = "types_some";
                        break;
                    case 'format':
                        tmpKey = "format_some";
                        break;
                    case 'scholarship':
                        tmpKey = "scholarship_some";
                        break;
                    case 'location':
                        tmpKey = "location_some";
                        break;
                    case 'language':
                        tmpKey = "languages_some";
                        break;
                    case 'sector':
                        tmpKey = "sector_some";
                        break;
                    default:
                        break;
                }
                if (key === "primary_focus" && filterCriteria[key].length) {
                    // filters['OR'] = [{ 'primary_focus_some': { 'id_in': [] } }, { 'secundary_focus_some': { 'id_in': [] } }]
                    // filters['OR'] = [{ 'primary_focus_some': { 'id_in': [] } }];

                    for (let i in filterCriteria[key]) {
                        let ids = [];
                        const tagList = filterCriteria[key];
                        if (filterCriteria['types'] && filterCriteria['types'].length) {
                            const validType = tag => {
                                const idByType = [];
                                for (let v = 0; v < filterCriteria['types'].length; v++) {
                                    const state_type = filterCriteria['types'][v].id
                                    for (let i = 0; i < tag.types.length; i++) {
                                        const tag_type_id = tag.types[i].id;
                                        if (tag_type_id === state_type) ids.push(tag.ids[i]);
                                    }
                                }
                                return idByType;
                            }
                            for (let i = 0; i < tagList.length; i++) {
                                const tag = tagList[i];
                                ids.concat(validType(tag));
                            }
                        } else {
                            console.log('aqui')
                            for (let i = 0; i < tagList.length; i++) {
                                console.log(tagList[i].ids);
                                ids = ids.concat(tagList[i].ids);
                            }
                        }

                        // for (let i = 0; i < ids.length; i++) {
                        //     const id = ids[i];
                        //     filters.OR[0].primary_focus_some.id_in.push(id);
                        //     filters.OR[1].secundary_focus_some.id_in.push(id);
                        // }

                        filters['primary_focus_some'] = { id_in: ids };
                    }
                } else if (key === "learning_methodology") {
                    filters['methodology'] = { 'value_gte': filterCriteria[key][0], 'value_lte': filterCriteria[key][1] };

                } else if (key === "seniority") {
                    filters['AND'] = [{ 'min_seniority': { 'value_lte': filterCriteria[key][1] } },
                    { 'max_seniority': { 'value_gte': filterCriteria[key][0] } }
                    ]
                } else {
                    filters[tmpKey] = { 'id_in': [] }
                    for (let v in filterCriteria[key]) {
                        const criteria = filterCriteria[key][v].id;
                        filters[tmpKey].id_in.push(criteria);
                    }
                }
            }
        }

        const filtersString = JSON.stringify(filters);
        localStorage.setItem('iter:filter', filtersString);

        if (filters && Object.keys(filters).length) this.getSolutionsFiltered(filtersString);
        else this.getAllSolutions(false);
        // if (this.sidebarRef && this.sidebarRef.current) this.sidebarRef.current.updateEqualizers();
    }

    //filters reseted and now clear storage
    resetFilter() {
        if (this.listTagFilter.current) this.listTagFilter.current.reset();

        localStorage.removeItem('iter:filter');
        localStorage.removeItem('iter:journey');
        localStorage.removeItem('iter:filtercriteria');
        localStorage.removeItem('iter:filter:taglist'); //todo
        localStorage.setItem('iter:type', "[]");
        this.setState({ type: [] });
        this.getAllSolutions()

        // if (this.sidebarRef && this.sidebarRef.current) this.sidebarRef.current.updateEqualizers();
    }

    //to reset filters
    onResetFilters() {
        if (this.sidebarRef && this.sidebarRef.current) this.sidebarRef.current.resetHandler();
    }

    addResultsToPortfolio() {
        const programs = this.state.programs;
        let currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio') ? localStorage.getItem('iter:portfolio') : "[]");

        let resultPortfolio = [];
        for (let i = 0; i < programs.length; i++) {
            resultPortfolio.push(programs[i].id)
        }
        let newPortfolio;
        if (!currentPortfolio) newPortfolio = resultPortfolio;
        else {
            newPortfolio = currentPortfolio.concat(resultPortfolio);
            newPortfolio = newPortfolio.filter((item, index) => {
                return (newPortfolio.indexOf(item) === index)
            });
        }
        localStorage.setItem('iter:portfolio', JSON.stringify(newPortfolio));
        this.setState({ currentPortfolio: newPortfolio, addedPortfolio: true });

        this.notify(true);
    }
    openSearch() {
        this.setState({ search: true });
    }
    closeSearch() {
        this.setState({ search: false });
    }

    notify = (added) => toast.info(added ? this.props.i18n.language === "pt" ? "Adicionados ao portefólio" : "Added to portfolio" : this.props.i18n.language === "pt" ? "Removidos do portefólio" : "Removed from portfolio", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    render() {
        const { sidebar, filterCriteria, programs, type, currentPortfolio, loading, addedPortfolio, animate, search } = this.state;
        const { t } = this.props

        return (
            <div className={sidebar ? "App bg dashboard opened-sidebar" : "App bg dashboard closed-sidebar"}>
                <Helmet title="Iter by Nova SBE Executive Education - Dashboard" />
                <Sidebar ref={this.sidebarRef} opened={sidebar} type={type} filters={filterCriteria} onFilter={this.onSidebarChange} onReset={this.resetFilter} onToggleSidebar={this.toggleSidebar} t={t} />
                <div className="main block">
                    <Nav toggle={true} onToggleClick={this.toggleSidebar} sidebar={sidebar} portfolio={currentPortfolio} openSearch={this.openSearch.bind(this)} onReset={this.onResetFilters} />
                    <div>
                        <div className="taglist-desktop">
                            <TagList
                                ref={this.listTagFilter}
                                id="primary_focus"
                                type={type}
                                title={t('dashboard.transformation_focus', 'Focus Transformation')}
                                onFilterChange={this.onTagsChange}
                            />
                        </div>
                        <div style={{ transitionDelay: window.innerWidth > 767 ? "550ms" : "200ms" }} className={!animate ? "wrapper animated" : "wrapper animated anim"}>
                            <div className="graph-info">
                                <div className="row max-200">
                                    <p className="f-s-14 c-blue">{programs.length > 10 ? t('dashboard.subtitle', '') : t('dashboard.subtitle_result', '')}</p>
                                    <p className="f-f-flayfair f-bold f-s-22 c-darkblue m-top-10">{programs.length > 10 ? t('dashboard.title', '') : t('dashboard.title_result', '')}</p>
                                </div>
                                <div>
                                    {loading ?
                                        <div className="row max-200">
                                            <Loading />
                                        </div>
                                        :
                                        <div className="row max-200">
                                            <span className="row f-f-flayfair f-bold f-s-44 c-blue">{programs.length}</span>
                                            <span className="f-s-14">{t('common.results', 'Results')}</span>
                                        </div>
                                    }
                                    <div className="row m-top-50 max-250">
                                        {!loading && !addedPortfolio && programs.length > 0 && programs.length <= 15 ?
                                            <div className="row">
                                                <div className="button icon small" onClick={this.addResultsToPortfolio}><span className="icon-plus"></span> <span>{t('btn.add_results_portfolio', 'Add results to portfolio')}</span></div>
                                            </div> : null}
                                        {addedPortfolio ?
                                            <div className="row">
                                                <span className="f-s-12 c-blue">{t('dashboard.results_added', 'Results added to portfolio')}</span>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <Graph solutions={programs} width="2000" height="800" start="400" anchor="1000" />
                        </div>
                    </div>
                </div>
                <PopupSearch show={search} onCloseSearch={this.closeSearch.bind(this)} />
                <ToastContainer />
            </div>
        )
    }
}

Dashboard.contextType = ServicesContext;

export default withTranslation()(Dashboard);