import React, { Component } from "react";
import FloatingLabelInput from 'react-floating-label-input';
import './InputField.scss';

class InputField extends Component {
    render() {
        return (
            <div className="input-field">
                <FloatingLabelInput
                    type={this.props.type}
                    fontSize={24}
                    id={this.props.id}
                    name={this.props.id}
                    label={this.props.label}
                    value={this.props.value}
                    onChange={this.props.onChange}
                />
            </div>
        )
    }
}

export default InputField;