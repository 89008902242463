import {
	ApolloClient,
	InMemoryCache,
	gql,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const SERVER_URL = process.env.REACT_APP_WEBSITE_NAME + '/admin/api';

let apolloClient = null;
const link = createUploadLink({
	uri: `${SERVER_URL}`,
	credentials: 'include',
});

function create() {
	return new ApolloClient({
		link,
		cache: new InMemoryCache({
		  addTypename: false
		}),
		defaultOptions: {
			query: {
				fetchPolicy: 'no-cache',
				errorPolicy: 'all',
			},
		},
	});
}

const makeQuery = client => graphqlquery => client
	.query({ query: gql`${graphqlquery}` })
	.then(response => (response && response.data) || response);

const makeMutation = client => (graphqlquery, variables) => client
	.mutate({ mutation: gql`${graphqlquery}`, variables })
	.then(response => (response && response.data) || response);

export { makeQuery, makeMutation };

export default function initApollo() {
	if (!apolloClient) {
		apolloClient = create();
	}

	return apolloClient;
}