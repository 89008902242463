export default function makeFiltersApi({
  query,
  mutation,
}) {

  const defaultFragment = `{
      id,
      pt,
      en
  }`;

  const userTypeFragment = `{
      id,
      pt,
      en,
      target
  }`;

  const experienceFragment = `{
     id,
     en,
     pt,
     type { id, target}
  }`;

  const focusFragment = `{
      id,
      color,
      en,
      pt,
      type { id, _label_},
      note
  }`;

  const iconFragment = `{
      id,
      pt,
      en,
      icon
  }`;
  const valueFragment = `{
      id,
      pt,
      en,
      value
  }`;

  return {
    getAll: (typeId) => query(`
            query getAllList {  
              allUserTypes(sortBy: order_ASC) ${iconFragment}
              allTransformationFoci(sortBy: order_ASC) ${focusFragment}
              allExperienceTypes(sortBy: order_ASC) ${experienceFragment} 
              allLearningMethodologies(sortBy: value_ASC) ${valueFragment}
              allLearningFormats(sortBy: order_ASC) ${iconFragment}
              allScholarships(sortBy: order_ASC) ${iconFragment}
              allSeniorities(sortBy: value_ASC) ${valueFragment}
              allLanguages(sortBy: order_ASC) ${defaultFragment}
              allLocations(sortBy: order_ASC) ${iconFragment}
              allSectors(sortBy: order_ASC) ${defaultFragment}
            }
        `),
    getAllFilters: (typeId) => query(`
            query getAllList {  
              allTransformationFoci(sortBy: order_ASC, where: { type: { target: ${typeId.toUpperCase()} } }) ${focusFragment}
              allExperienceTypes(sortBy: order_ASC, where: { type: { target: ${typeId.toUpperCase()} } }) ${defaultFragment} 
              allLearningMethodologies(sortBy: value_ASC) ${valueFragment}
              allLearningFormats(sortBy: order_ASC) ${iconFragment}
              allScholarships(sortBy: order_ASC) ${iconFragment}
              allSeniorities(sortBy: value_ASC) ${valueFragment}
              allLanguages(sortBy: order_ASC) ${defaultFragment}
              allLocations(sortBy: order_ASC) ${iconFragment}
              allSectors(sortBy: order_ASC) ${defaultFragment}
            }
        `),
    getAllUserTypes: () => query(`
          query {
            allUserTypes(sortBy: order_ASC) ${userTypeFragment}
          }
        `),
    getAllFocus: () => query(`
          query {
            allTransformationFoci(sortBy: order_ASC) ${focusFragment}
          }
        `),
    getFocusByType: (typeId) => query(`
          query {
            allTransformationFoci(sortBy: order_ASC, where: { type: { target: ${typeId.toUpperCase()} } }) ${focusFragment}
          }
        `),
    getFocusByTypes: (types) => query(`
          query {
            allTransformationFoci(sortBy: order_ASC, where: { type: { id_in: ${types} } }) ${focusFragment}
          }
        `),
    getAllExperienceTypes: () => query(`
          query {
            allExperienceTypes(sortBy: order_ASC) ${defaultFragment}
          }
        `),
    getAllLearningFormats: () => query(`
          query {
            allLearningFormats(sortBy: order_ASC) ${iconFragment}
          }
        `),

    getAllLanguages: () => query(`
          query {
            allLanguages(sortBy: order_ASC) ${defaultFragment}
          }
        `),
    getAllLocations: () => query(`
          query {
            allLocations(sortBy: order_ASC) ${iconFragment}
          }
        `),
    getAllSectors: () => query(`
          query {
            AllSectors(sortBy: order_ASC) ${defaultFragment}
          }
        `)
  };
}