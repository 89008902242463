import React, { Component } from "react";

import ReactApexChart from 'react-apexcharts';

class AreaChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'area',
                    height: 'auto',
                    toolbar: { show: false },
                    animations: {
                        enabled: false
                    }
                },
                fill: {
                    type: 'solid',
                    colors: ['#274e7d', "#587ca9"],
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    colors: ['transparent', "#fff"],
                    width: 2,
                    dashArray: 4,      
                },
                tooltip: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    labels: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        }
                    },
                    padding: {
                        left: 0,
                        right: 0
                    }
                }
            },
        };
    }


    render() {
        return (
            <div className="pad-10" style={{ 'height': 90  }}>
                <ReactApexChart 
                    options={this.state.options} 
                    series={[{
                        name: 'series1',
                        data: this.props.chartArray,
                    }, {
                        name: 'series2',
                        data: this.props.overChart
                    }]} 
                    type="area" 
                    height={100} 
                />
            </div>
        )
    }
}

export default AreaChart;