import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { ServicesContext } from '../../context/services';
import './Popup.scss';
import InputField from '../ui/InputField';
import SelectField from "../ui/SelectField";

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

const countErrors = (errors) => {
    let count = 0;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (count = count + 1)
    );
    return count;
}

class PopupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: localStorage.getItem('iter:type'),
            target: '',
            name: '',
            surname: '',
            phone: '',
            email: '',
            company: '',
            role: '',
            emailcontact: false,
            phonecontact: false,
            privacy: false,
            formValid: false,
            errorCount: null,
            errors: {
                name: '',
                surname: '',
                phone: '',
                email: '',
                company: '',
                role: '',
            },
            sent: false,
            currentLead: null
        }
        this.onClosePopup = this.onClosePopup.bind(this);
    }

    onClosePopup() {
        this.setState({
            type: '',
            name: '',
            target: '',
            surname: '',
            phone: '',
            email: '',
            company: '',
            role: '',
            emailcontact: false,
            phonecontact: false,
            privacy: false,
            formValid: false,
            errorCount: null,
            errors: {
                name: '',
                surname: '',
                phone: '',
                email: '',
                company: '',
                role: '',
            },
            defaultError: false,
            sent: false,
            currentLead: null
        });
        this.props.onClose();
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        let errors = this.state.errors;

        switch (name) {
            case 'name':
                errors.name = value.length < 3 ? 'Nome inválido!' : '';
                break;
            case 'surname':
                errors.surname = value.length < 2 ? 'Apelido inválido!' : '';
                break;
            case 'phone':
                errors.phone = value.length < 9 ? 'Telefone inválido!' : '';
                break;
            case 'email':
                errors.email = validEmailRegex.test(value) ? '' : 'E-mail invalido';
            case 'company':
                errors.company = this.state.type === "B2B" && value.length < 2 ? 'Empresa inválida!' : '';
                break;
            default:
                break;
        }

        console.log(name, value);
        this.setState({ errors, [name]: name === "phonecontact" || name === "emailcontact" || name === "privacy" ? event.target.checked : value });
    }

    validate() {
        let { name, surname, email, phone, privacy, target } = this.state;
        const { t } = this.props;

        const isValidName = name.length < 3 ? null : true;

        const isValidSurname = surname.length < 3 ? null : true;

        const isValidPhone = phone.length < 9 ? null : true;

        const isValidTarget = target.length ? true : false;

        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        const isValidEmail = pattern.test(email);

        const isPrivacyValid = privacy;

        return isValidName && isValidSurname && isValidEmail && isValidPhone && isValidTarget && isPrivacyValid;
    }

    onSubmit() {
        const isValid = this.validate();

        this.setState({ defaultError: !isValid })

        if (!isValid) {
            return;
        }
        const { services } = this.context;

        let fields = {
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            phone: this.state.phone,
            company: this.state.company,
            role: this.state.role,
            phonecontact: this.state.phonecontact,
            emailcontact: this.state.emailcontact,
            target: this.state.target,
            lang: this.props.i18n.language.toUpperCase(),
        }

        if (this.props.campaign) {
            fields.campaign_id = this.props.campaign;
        }

        let data = '';

        for (var key in fields) {
            if (data.length) data += ',';
            if (fields[key] === true || fields[key] === false || fields[key] === "B2C" || fields[key] === "B2B" || fields[key] === "PT" || fields[key] === "EN") data += `${key}: ${fields[key]}`;
            else data += `${key}: "${fields[key]}"`;
        }

        const ids = this.props.portfolio.length === 1 ? this.props.portfolio : JSON.parse(localStorage.getItem('iter:portfolio'));

        let solutions = '['

        for (let i = 0; i < ids.length; i++) {
            solutions += '{id:"' + ids[i] + '"},';

        }
        solutions = solutions.slice(0, -1);
        solutions += ']';

        if (ids) {
            data += `, solutions: { connect:  ${solutions}  }`;
        }

        services.leadsApi.add(data)
            .then(({ createLead }) => {
                console.log(createLead)
                if (createLead) this.props.onPrint();
            }).catch(e => { console.log(e) });
    }

    render() {
        const { t } = this.props;
        const { errors, sent, currentLead, type } = this.state;

        return (
            this.props.show ? <div className="viewport">
                <div className="modal form">
                    <div className="row">
                        <p className="f-s-12 c-darkblue">{t('popup_form.your_portfolio', 'Your Portfolio')}</p>
                    </div>
                    <div className="row m-top-5">
                        <span className="f-f-flayfair f-bold f-s-24">{this.props.portfolio.length === 1 ? t('popup_form.title_single', 'Download the program') : t('popup_form.title', 'Download all programs')} <span className="c-blue">({this.props.portfolio.length})</span></span>
                    </div>
                    <div className="row sep m-top-35"></div>
                    {!sent ?
                        <div className="row fields">
                            <div className="row m-top-35">
                                <span className="f-f-flayfair f-s-24">{this.props.portfolio.length === 1 ? t('popup_form.description_single', 'To download the program we need some information from you.') : t('popup_form.description', 'To download all programs we need some information from you.')}</span>
                            </div>
                            <div className="row">
                                <InputField id="name" label={t('popup_form.name', 'Name')} onChange={this.handleChange} />
                                {errors.name.length > 0 && <span className='error'>{errors.name}</span>}
                            </div>
                            <div className="row">
                                <InputField id="surname" label={t('popup_form.surname', 'Surname')} onChange={this.handleChange} />
                                {errors.surname.length > 0 && <span className='error'>{errors.surname}</span>}
                            </div>
                            <div className="row">
                                <InputField id="phone" label={t('popup_form.phone', 'Phone')} onChange={this.handleChange} />
                                {errors.phone.length > 0 && <span className='error'>{errors.phone}</span>}
                            </div>
                            <div className="row">
                                <InputField id="email" type="email" label={t('popup_form.email', 'E-mail')} onChange={this.handleChange} />
                                {errors.email.length > 0 && <span className='error'>{errors.email}</span>}
                            </div>
                            <div className="row">
                                <SelectField id="target" onChange={this.handleChange} />
                            </div>
                            {
                                (this.state.target == 'Corporate Buyer') ?
                                    <>
                                        <div className="row">
                                            <InputField id="company" label={t('popup_form.company', 'Company')} onChange={this.handleChange} />
                                            {errors.company.length > 0 && <span className='error'>{errors.company}</span>}
                                        </div>
                                        <div className="row">
                                            <InputField id="role" label={t('popup_form.role', 'Role')} onChange={this.handleChange} />
                                        </div>
                                    </>
                                    : null
                            }
                            <div className="row m-top-20">
                                <p className="f-s-12 c-darkblue o-40">{t('popup_form.contact_txt', 'How do you like us to contact you?')}</p>
                            </div>
                            <div className="row flex m-top-5">
                                <div className="checkform">
                                    <input type="checkbox" id="phonecontact" name="phonecontact" onChange={this.handleChange} checked={this.state.phonecontact} />
                                    <label htmlFor="phonecontact" className="f-f-flayfair f-s-24">{t('popup_form.phone', 'Phone')}</label>
                                </div>
                                <div className="checkform">
                                    <input type="checkbox" id="emailcontact" name="emailcontact" onChange={this.handleChange} checked={this.state.emailcontact} />
                                    <label htmlFor="emailcontact" className="f-f-flayfair f-s-24">{t('popup_form.email', 'E-mail')}</label>
                                </div>
                            </div>
                            <div className="row sep m-top-45"></div>
                            <div className="row m-top-30 privacy">
                                <div className="checkprivacy">
                                    <input type="checkbox" id="privacy" name="privacy" onChange={this.handleChange} checked={this.state.privacy} />
                                    <label htmlFor="privacy" >
                                        <span className="f-s-12 c-darkblue o-40">{t('popup_form.privacy_txt', 'I agree to receieve NOVA SBE comunications to my selected contacts. For further information please read the Privacy Policy')}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row flex jc-sb m-top-45">
                                <div className="button small secundary" onClick={this.onClosePopup}>{t('btn.back', 'Back')}</div>
                                <div className="button small" onClick={this.onSubmit.bind(this)}>{t('btn.submit', 'Submit')}</div>
                            </div>
                            <div className="row m-top-10">
                                {this.state.defaultError ? <span className='error'>Valide se os campos estão preenchidos corretamente.</span> : null}
                            </div>
                        </div>
                        : <div className="row sent">
                            <div className="row m-top-35">
                                <span className="f-f-flayfair f-s-24">{t('popup_form.sent_title', 'Thank you.')}</span>
                            </div>
                            <div className="row m-top-5">
                                <span className="f-f-flayfair f-s-18">{t('popup_form.access_portfolio', 'Click here to access your tailored portfolio.')}</span>
                            </div>
                            <div className="row flex jc-sb m-top-45">
                                <div className="button small secundary" onClick={this.onClosePopup}>{t('btn.close', 'Close')}</div>
                                <a className="button small" href={process.env.REACT_APP_WEBSITE_NAME + "/pdf/" + currentLead}>{t('popup_form.download_portfolio', 'Download Portfolio')}</a>
                            </div>
                        </div>}
                </div>
            </div> : null
        )
    }
}

PopupForm.contextType = ServicesContext;

export default withTranslation()(PopupForm);