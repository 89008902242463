import React, { Component } from "react";
import './SelectField.scss';

import { withTranslation } from 'react-i18next';

class SelectField extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="input-field">
                <select id={this.props.id} name={this.props.id} defaultValue="" onChange={this.props.onChange}>
                    <option value="" disabled hidden>{t('popup_form.looking_for', 'Target')}</option>
                    <option value="Student">{t('popup_form.looking_for_option_1', 'Student')}</option>
                    <option value="Corporate Buyer">{t('popup_form.looking_for_option_2', 'Corporate')}</option>
                </select>
            </div>
        )
    }
}

export default withTranslation()(SelectField);