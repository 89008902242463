import React, { Component } from "react";
import { withTranslation, I18nContext } from 'react-i18next';
import './Card.scss';
import PdfPreview from "../../components/ui/PdfPreview";


import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';


import { ToastContainer, toast } from 'react-toastify';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';
import PopupForm from "./PopupForm";


class Card extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadForm: false,
            pdf: null,
            video: null
        }

        this.onChange = this.props.onChange;
        this.onAddToPortfolio = this.onAddToPortfolio.bind(this);
        this.onRemoveFromPortfolio = this.onRemoveFromPortfolio.bind(this);
        this.onPopup = this.onPopup.bind(this);
    }

    onAddToPortfolio() {
        const currentId = this.props.solution.id;
        let currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio'));

        if (!currentPortfolio) currentPortfolio = [currentId];
        else {
            if (!currentPortfolio.includes(currentId)) currentPortfolio.push(currentId);
        }

        localStorage.setItem('iter:portfolio', JSON.stringify(currentPortfolio));
        this.onChange();
        this.notify(true);
    }

    onRemoveFromPortfolio() {
        const currentId = this.props.solution.id;
        let currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio'));

        var index = currentPortfolio.indexOf(currentId);
        if (index > -1) currentPortfolio.splice(index, 1);

        localStorage.setItem('iter:portfolio', JSON.stringify(currentPortfolio));
        this.onChange();
        this.notify(false);
    }

    onOpenPdf() {
        const pdfUrl = this.props.i18n.language === "pt" ? this.props.solution.file_pt.publicUrl : this.props.solution.file_en.publicUrl;
        console.log('pdfUrl', pdfUrl);
        this.setState({ pdf: pdfUrl })
    }

    openVideo() {
        console.log('openmodal', this.state.video)
        this.setState({ video: true })
    }

    getYouTubeId(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    onPopup() {
        const popupState = this.state.downloadForm;
        this.setState({ downloadForm: !popupState, pdf: null });
    }

    notify = (added) => toast.info(added ? this.props.i18n.language === "pt" ? "Adicionado ao portefólio" : "Added to portfolio" : this.props.i18n.language === "pt" ? "Removido do portefólio" : "Removed from portfolio", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

    getDetailURL(program){
        let url = '/';
        const slug = this.props.i18n.language === 'pt' ? program.url_pt : program.url_en;
        url += 'detail/' + slug;
        //url += program.id;
        return url;
    }

    render() {
        const { t, i18n, solution, small, printing } = this.props;
        const { pdf, video, downloadForm } = this.state;

        let currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio') ? localStorage.getItem('iter:portfolio') : "[]");
        const added = currentPortfolio ? currentPortfolio.indexOf(this.props.solution.id) : -1;

        return (
            <div className="card" style={{ boxShadow: printing ? 'none' : 'inherit' }}>
                {solution.type.en === "Program" ?
                    <div className="row">
                        <div className="row">
                            <p className="f-s-12">{solution.experience[i18n.language]}</p>
                        </div>
                        <div className="row m-top-5">
                            <div>
                                <h1 className="f-f-flayfair f-bold f-s-24 c-blue" style={{ color: solution.primary_focus[0].color }}>{solution[i18n.language]}</h1>
                            </div>
                        </div>
                        <div className="row flex jc-sb ai-top">
                            <div>
                                {solution.format.map(item => (
                                    <div key={item.id} className="row flex m-top-10"><span className={item.icon} style={{ color: solution.primary_focus[0].color }}></span> <span className="f-s-11 m-left-5">{item[i18n.language]}</span></div>
                                ))
                                }
                            </div>
                            {i18n.language === "pt" && solution.duration_pt || i18n.language === "en" && solution.duration_en ? <div className="row inline flex a-center m-top-10"><span className="icon-duration" style={{ color: solution.primary_focus[0].color }}></span> <span className="f-s-11 m-left-5">{i18n.language === "pt" ? solution.duration_pt : solution.duration_en}</span></div> : null}
                        </div>
                        <div className="row sep m-top-35"></div>
                        {
                            printing ? 
                            <div className="row m-top-35">
                                <p className="f-s-14">{i18n.language === "pt" ? solution.summary_pt : solution.summary_en}</p>
                                <div className="row m-top-20">
                                    { solution.for_you_pt ? 
                                        <>
                                            <div className="row">
                                                <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Para si" : "For you"}</p>
                                            </div>
                                            <div className="row m-top-10">
                                                <p className="f-s-14" dangerouslySetInnerHTML={{ __html: i18n.language === "pt" ? solution.for_you_pt : solution.for_you_en }}></p>
                                            </div>
                                        </> 
                                    : null }
                                    { solution.for_organization_pt ? 
                                        <>
                                            <div className="row m-top-20">
                                                <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Para a sua organização" : "For your organization"}</p>
                                            </div>
                                            <div className="row m-top-10">
                                                <p className="f-s-14" dangerouslySetInnerHTML={{ __html: i18n.language === "pt" ? solution.for_organization_pt : solution.for_organization_en }}></p>
                                            </div>
                                        </> 
                                    : null }
                            </div>
                            </div> : null
                        }
                    </div>
                    :
                    <div className="row">
                        <div className="row m-top-5">
                            <div>
                                <h1 className="f-f-flayfair f-bold f-s-24 c-blue" style={{ color: solution.primary_focus[0].color }}>{solution[i18n.language]}</h1>
                                <p className="f-s-12">{i18n.language === "pt" ? solution.speaker_title_pt : solution.speaker_title_en}</p>
                            </div>
                        </div>
                        <div className="row flex jc-sb ai-top">
                            <div>
                                <div className="row flex m-top-10">
                                    <span className="icon-global" style={{ color: solution.primary_focus[0].color }}></span>
                                    <span className="f-s-11 m-left-5">{i18n.language === "pt" ? solution.speaker_languages_pt : solution.speaker_languages_en}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row sep m-top-35"></div>
                    </div>
                }
                {!small ?
                    <div>
                        <div className="row m-top-35">
                            <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Desafio" : "Challenge"}</p>
                        </div>
                        <div className="row m-top-10">
                            <p className="f-s-14">{i18n.language === "pt" ? solution.summary_pt : solution.summary_en}</p>
                        </div>

                        <div className="row flex buttons m-top-35" style={{ opacity: printing ? 0 : 1 }}>
                            {i18n.language === "pt" && solution.file_pt || i18n.language === "en" && solution.file_en ?
                                <div className="button small icon secundary" style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }} onClick={this.onOpenPdf.bind(this)}>
                                    <span className="icon-preview"></span> {t('btn.preview', 'Preview')}
                                </div>
                                : null}
                            {i18n.language === "pt" && solution.video_pt || i18n.language === "en" && solution.video_en ?
                                <div className="button small secundary m-left-10" style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }} onClick={this.openVideo.bind(this)}>
                                    {t('btn.watch_video', 'Watch video')}
                                </div>
                                : null}
                        </div>
                        <div className="row sep m-top-35"></div>
                    </div> : null}
                <div className="row flex m-top-35 bottom" style={{ opacity: printing ? 0 : 1 }}>
                    {
                        added < 0 ? <div className="button small icon" onClick={this.onAddToPortfolio} style={{ backgroundColor: solution.primary_focus[0].color }}><span className="icon-book"></span><span>{t('btn.add', 'Add to your Portfolio')}</span></div>
                            : <div className="button small icon secundary" onClick={this.onRemoveFromPortfolio} style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }}><span className="icon-delete"></span><span>{t('btn.remove', 'Remove from your Portfolio')}</span></div>
                    }
                    {!small ?
                        <>
                            {solution.has_landing_page ?
                                <a className="button small secundary m-left-10" href={solution.landing_page} target="_blank" style={{ borderColor: solution.primary_focus[0].color, color: solution.primary_focus[0].color }}><span>{t('btn.learn_more', 'Learn More')}</span></a>
                                :
                                <div className="button small secundary m-left-10" onClick={this.onPopup} style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }}><span>{t('btn.download', 'Download')}</span></div>
                            }
                        </> : <a className="button small secundary m-left-10" href={this.getDetailURL(solution)} target="_self" style={{ borderColor: solution.primary_focus[0].color, color: solution.primary_focus[0].color }}><span>{t('btn.learn_more', 'Learn More')}</span></a>}
                </div>
                {pdf ? <PdfPreview file={pdf} solution={solution} added={added} addToPortfolio={this.onAddToPortfolio} removeFromPortfolio={this.onRemoveFromPortfolio} popupForm={this.onPopup} onClosePdf={() => this.setState({ pdf: null })} /> : null}
                {video ? <ModalVideo channel='youtube' isOpen={video} videoId={i18n.language === "pt" ? this.getYouTubeId(solution.video_pt) : this.getYouTubeId(solution.video_en)} onClose={() => this.setState({ video: false })} /> : null}
                <ToastContainer />
                <PopupForm show={downloadForm} onClose={this.onPopup} portfolio={[solution.id]} />
            </div>
        )
    }
}

export default withTranslation()(Card);