import React, { Component } from "react";
import { ServicesContext } from '../../context/services';
import './CheckboxCircle.scss';

class CheckboxCircle extends Component {
    constructor(props) {
        super(props);

        const options = this.props.options;
        const savedOptions = JSON.parse(localStorage.getItem('iter:filter:' + this.props.id));

        if (savedOptions && savedOptions.length) {
            var state = savedOptions.reduce(function (map, obj) { map[obj.id] = obj.checked; return map; }, {});
            for (var i = 0; i < options.length; i++) {
                options[i].checked = state[options[i].id];
            }
        }

        this.state = { options };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options.length != this.props.options.length) {
            this.setState({ options: this.props.options });
            console.log('alterou');
        }
    }

    reset() {
        if (this.state.options) {
            const options = this.state.options;
            options.filter(option => {
                option.checked = false;
            });
            localStorage.removeItem('iter:filter:' + this.props.id);
        }
    }

    handleChange(index, event) {
        const options = this.state.options;
        options[index].checked = event.currentTarget.checked;

        const optionChecked = options.filter(option => {
            if (option.checked === true) return option;
        });

        this.props.onFilterChange(this.props.id, optionChecked);
        localStorage.setItem('iter:filter:' + this.props.id, JSON.stringify(options));
    }

    render() {
        const { id, title } = this.props;
        const { options } = this.state;

        return (
            <div className="row m-top-35" ref={this.props.sectorEl} id={id}>
                <h4 className="f-f-flayfair f-bold f-s-14">{title}</h4>
                {options ? options.map((item, index) => (
                    <div key={item.id} className="row checkcircle m-top-15">
                        <input type="checkbox" id={item.id} name={id} onChange={this.handleChange.bind(this, index)} checked={item.checked ? true : false} />
                        <label htmlFor={item.id}>{item[this.props.lang]}</label>
                    </div>
                )) : null}
            </div>
        )
    }
}
CheckboxCircle.contextType = ServicesContext;
export default CheckboxCircle;