import React, { Component } from "react";
import { withTranslation  } from 'react-i18next';
import './Popup.scss';

class PopupWarning extends Component {

    onAction(action, currentUserType){
        let typePage;
        if(action === "change"){
            //clear filters
            localStorage.removeItem('iter:filter:experience');
            localStorage.removeItem('iter:filter:format');
            localStorage.removeItem('iter:filter:location');
            localStorage.removeItem('iter:filter:language');
            localStorage.removeItem('iter:filter:sector');
            localStorage.removeItem('iter:filter:taglist');
            localStorage.removeItem('iter:filter:learning_methodology');
            localStorage.removeItem('iter:filter:seniority');
            localStorage.removeItem('iter:filter');
            localStorage.removeItem('iter:filtercriteria');
            localStorage.removeItem('iter:journey');
            localStorage.removeItem('iter:portfolio');

            typePage = currentUserType === "B2C" ? "/B2B" : "/B2C";
        }else typePage = currentUserType === "B2C" ? "/B2C" : "/B2B";

        if(window.location.pathname === typePage) this.props.onClose();
        else window.location = typePage;
    }

    render() {
        const { t } = this.props;
        const currentUserType = localStorage.getItem('iter:type');

        return (
            this.props.show ? <div className="viewport">
                <div className="modal a-center">
                    <div className="row icon">
                        <span className="icon-info"></span>
                    </div>
                    <div className="row m-top-5">
                        <span className="f-f-flayfair f-bold f-s-24">{t('popup_warning.title', 'Warning')}</span>
                    </div>
                    <div className="row m-top-20 max-270 m-auto">
                        <p className="f-s-14 c-darkblue">{t('popup_warning.subtitle', 'Your custom journey will be lost if you change the type of user.')}</p>
                    </div>
                    <div className="row m-top-45 max-330 m-auto">
                        <div className="row">
                            <div onClick={this.onAction.bind(this, 'keep', currentUserType)} className="button w-100">{ currentUserType === 'B2C' ? t('popup_warning.keep_personal', '') : t('popup_warning.keep_company', '') }</div>
                        </div>
                        <div className="row m-top-10">
                            <div onClick={this.onAction.bind(this, 'change', currentUserType)} className="button secundary w-100">{ currentUserType === 'B2C' ? t('popup_warning.change_company', '') : t('popup_warning.change_personal', '') }</div>
                        </div>
                    </div>
                </div>
            </div> : null
        )
    }
}

export default withTranslation()(PopupWarning);