import React, { Suspense } from 'react';
import { hydrate, render } from "react-dom";
import './styles/main.scss';
import App from './App'; 
import * as serviceWorker from './serviceWorker'; 

 import HttpsRedirect from 'react-https-redirect';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<HttpsRedirect>
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>
  </HttpsRedirect>, rootElement);
} else {
  render(<HttpsRedirect>
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>
  </HttpsRedirect>, rootElement);
}

serviceWorker.unregister(); 
