import React, { Component, createRef } from "react";
import { withTranslation } from 'react-i18next';
import './DetailCard.scss';
import PdfPreview from "../ui/PdfPreview";
import PopupForm from "./PopupForm";

import ModalVideo from 'react-modal-video';
import { ToastContainer, toast } from 'react-toastify';

import ReactToPrint from "react-to-print";

import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';

class Card extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadForm: false,
            pdf: null,
            video: null,
            open: true,
            printing: false,
        }

        this.onChange = this.props.onChange;
        this.onAddToPortfolio = this.onAddToPortfolio.bind(this);
        this.onRemoveFromPortfolio = this.onRemoveFromPortfolio.bind(this);
        this.onPopup = this.onPopup.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.componentRef = createRef();
        this.printRef = createRef();

    }

    onAddToPortfolio() {
        const currentId = this.props.solution.id;
        let currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio'));

        if (!currentPortfolio) currentPortfolio = [currentId];
        else {
            if (!currentPortfolio.includes(currentId)) currentPortfolio.push(currentId);
        }

        localStorage.setItem('iter:portfolio', JSON.stringify(currentPortfolio));
        this.onChange();
        this.notify(true);
    }

    onRemoveFromPortfolio() {
        const currentId = this.props.solution.id;
        let currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio'));

        var index = currentPortfolio.indexOf(currentId);
        if (index > -1) currentPortfolio.splice(index, 1);

        localStorage.setItem('iter:portfolio', JSON.stringify(currentPortfolio));
        this.onChange();
        this.notify(false);
    }

    onOpenPdf() {
        const pdfUrl = this.props.i18n.language === "pt" ? this.props.solution.file_pt.publicUrl : this.props.solution.file_en.publicUrl;
        console.log('pdfUrl', pdfUrl);
        this.setState({ pdf: pdfUrl })
    }

    openVideo() {
        console.log('openmodal', this.state.video)
        this.setState({ video: true })
    }

    toggleOpen(){
        this.setState({ open: !this.state.open })
    }

    getYouTubeId(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    onPopup() {
        const popupState = this.state.downloadForm;
        this.setState({ downloadForm: !popupState, pdf: null });
    }

    onPrint () {
        this.setState({ printing: true, downloadForm: false });
        setTimeout(() => {
            this.printRef.current.handleClick();
        }, 500);
    }

    notify = (added) => toast.info(added ? this.props.i18n.language === "pt" ? "Adicionado ao portefólio" : "Added to portfolio" : this.props.i18n.language === "pt" ? "Removido do portefólio" : "Removed from portfolio", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

    getDetailURL(program) {
        let url = '';
        const slug = this.props.i18n.language === 'pt' ? program.url_pt : program.url_en;
        url += 'detail/' + slug;
        return url;
    }


    render() {
        const { t, i18n, solution, small } = this.props;
        const { pdf, video, downloadForm, printing, open } = this.state;

        let currentPortfolio = JSON.parse(localStorage.getItem('iter:portfolio') ? localStorage.getItem('iter:portfolio') : "[]");
        const added = currentPortfolio ? currentPortfolio.indexOf(this.props.solution.id) : -1;

        const renderButtons = () => {
            return <div style={{ opacity: printing ? 0 : 1, display: 'flex', alignItems: 'top' }}>
                {   solution.type.en !== 'Keynote' ? (
                        added < 0 ? <div className="button small icon" onClick={this.onAddToPortfolio} style={{ backgroundColor: solution.primary_focus[0].color }}><span className="icon-book"></span><span>{t('btn.add', 'Add to your Portfolio')}</span></div>
                            : <div className="button small icon secundary" onClick={this.onRemoveFromPortfolio} style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }}><span className="icon-delete"></span><span>{t('btn.remove', 'Remove from your Portfolio')}</span></div>
                    ) : null
                }
                {i18n.language === "pt" && solution.video_pt || i18n.language === "en" && solution.video_en ?
                    <div className="button small secundary m-left-10" style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }} onClick={this.openVideo.bind(this)}>
                        {t('btn.watch_video', 'Watch video')}
                    </div>
                : null }
                {   solution.type.en !== 'Keynote' ? (solution.has_landing_page ?
                        <a className="button small secundary m-left-10" href={solution.landing_page} target="_blank" style={{ borderColor: solution.primary_focus[0].color, color: solution.primary_focus[0].color }}><span>{t('btn.learn_more', 'Learn More')}</span></a>
                        :
                        <div className="button small secundary m-left-10" onClick={this.onPopup} style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }}><span>{t('btn.print', 'Imprimir')}</span></div>
                    ) : null
                }
                {solution.file_pt ? 
                    <a href={i18n.language === "pt" ? solution.file_pt.publicUrl : solution.file_en.publicUrl} target="_blank" className="button small secundary m-left-10" style={{ color: solution.primary_focus[0].color, borderColor: solution.primary_focus[0].color }} download>
                        <span>{t('btn.download', 'Download')}</span>
                    </a> : null }
            </div>
        }

        const speaker_photo = solution.speaker_photo && solution.speaker_photo.publicUrl ? solution.speaker_photo.publicUrl : '';

        return (
            <>
            <div ref={el => (this.componentRef = el)} className={ printing ? "detail-card printing" : (open ? "detail-card" : "detail-card colapsed")} style={{ color: solution.primary_focus[0].color }}>
                <div className="toggle-button">
                    <div>
                        <div className="button small even-small" onClick={this.toggleOpen} style={{ backgroundColor: solution.primary_focus[0].color }}><span>{this.state.open ? t('btn.colapse', 'Colapsar') :  t('btn.open', 'Explorar')}</span></div>
                    </div>
                </div>
                <div className="row">
                    <div className="card-content m-top-10">
                        <div className="row flex jc-sb" style={ {width: open ? 'auto' : 'inerith'} }>
                            <div className="content-column" style={{alignSelf: 'flex-start'}}>
                                <div className="row flex m-top-5" style={{alignItems: 'flex-start', gap: 10}}>
                                    {speaker_photo ? 
                                    <div className="avatar-container" style={{width: open ? 280 : 0, opacity: open ? 1 : 0, overflow: 'hidden', transition: 'all 275ms' }}>
                                        <div className="avatar" style={{backgroundImage: "url('" + speaker_photo + "')" }}>
                                        </div>
                                    </div>
                                    : null }
                                    <div className="row">
                                        <div className="row flex jc-sb" style={{gap: 20}}>
                                            <div>
                                                <div className="row">
                                                    <p className="f-s-12">{solution.experience[i18n.language]}</p>
                                                </div>
                                                <h1 className="f-f-flayfair f-bold f-s-24 c-blue" style={{ color: solution.primary_focus[0].color }}>{solution.speaker_name}</h1>
                                            </div>
                                            <div className="buttons-desktop">
                                                <div className="content-column jc-flex-end" style={ {display: printing ? 'none' : 'flex', opacity: open ? 1 : 0, transition: 'all 200ms', transitionDelay: '500ms', alignSelf: 'flex-start'}}>
                                                    { open ? renderButtons() : null }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-top-20">
                                            <div className="row">
                                                <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? solution.speaker_title_pt : solution.speaker_title_en}</p>
                                            </div>
                                            <div className="row m-top-10">
                                                <p className="f-s-14">{i18n.language === "pt" ? solution.speaker_bio_pt : solution.speaker_bio_en}</p>
                                            </div>
                                            {
                                            solution.type.en === 'Keynote' || solution.type.en === 'Speaker' && solution.contact_info_name ?
                                            <>
                                                <div className="row sep m-top-20"></div>
                                                <div className="row m-top-5">
                                                    <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? 'Contacte-nos para mais informações:' : 'Contact us for more information:'}</p>
                                                    <div className="row flex wrap" style={{display: !open ? 'block' : 'flex'}}>
                                                        <div className="row inline flex m-top-10  m-right-25">
                                                            <p className="f-s-12">{solution.contact_info_name}</p>
                                                        </div>
                                                        { solution.contact_info_email ? 
                                                        <div className="row inline flex m-top-10  m-right-25">
                                                            <span className="icon-mail" style={{ color: solution.primary_focus[0].color }}></span>
                                                            <span className="f-s-11 m-left-5">{solution.contact_info_email}</span>
                                                        </div> : null }

                                                        { solution.contact_info_phone ? 
                                                        <div className="row inline flex m-top-10">
                                                            <span className="icon-phone" style={{ color: solution.primary_focus[0].color }}></span>
                                                            <span className="f-s-11 m-left-5"> {solution.contact_info_phone}</span>
                                                        </div> : null }
                                                    </div>
                                                </div>
                                            </>
                                            : null 
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row sep m-top-35"></div>
                </div>
                
                <div className="card-content m-top-35">
                    {/* conteúdo */}
                    <div className="row" style={{width: '100%'}}> 
                        <h1 className="f-f-flayfair f-s-24 c-darkblue">{solution[i18n.language]}</h1>
                    </div>
                    <div className="row flex ai-top" style={{width: solution.type.en === "Keynote" ? '100%': 'inerith'}}>
                        <div className="content-column">
                            <div>
                                <div className="row flex wrap">
                                     { i18n.language === "pt" && solution.speaker_languages_pt || solution.speaker_languages_en ? 
                                    <div className="row inline flex m-top-10  m-right-25">
                                        <span className="icon-global" style={{ color: solution.primary_focus[0].color }}></span>
                                        <span className="f-s-11 m-left-5">{i18n.language === "pt" ? solution.speaker_languages_pt : solution.speaker_languages_en}</span>
                                    </div> : null }
                                    {solution.format ? solution.format.map(item => (
                                        <>
                                            <div key={item.id} className="row inline flex m-top-10 m-right-25">
                                                <span className={item.icon} style={{ color: solution.primary_focus[0].color }}></span>
                                                <span className="f-s-11 m-left-5">{item[i18n.language]}</span>
                                            </div>
                                        </>
                                    )) : null }
                                    { i18n.language === "pt" &&  solution.duration_pt || solution.duration_en ? 
                                    <div className="row inline flex m-top-10  m-right-25">
                                        <span className="icon-duration" style={{ color: solution.primary_focus[0].color }}></span>
                                        <span className="f-s-11 m-left-5">{i18n.language === "pt" ? solution.duration_pt : solution.duration_en}</span>
                                    </div> : null }
                                </div>
                            </div>
                            <div className="row m-top-20">
                                <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Desafio" : "Challenge"}</p>
                            </div>
                            <div className="row m-top-10">
                                <p className="f-s-14">{i18n.language === "pt" ? solution.summary_pt : solution.summary_en}</p>
                            </div>
                        </div>
                        <div className="content-column" style={{display: solution.type.en === "Keynote" ? 'none': 'block'}}>
                            { solution.for_you_pt ? 
                                <>
                                    <div className="row">
                                        <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Para si" : "For you"}</p>
                                    </div>
                                    <div className="row m-top-10">
                                        <p className="f-s-14" dangerouslySetInnerHTML={{ __html: i18n.language === "pt" ? solution.for_you_pt : solution.for_you_en }}></p>
                                    </div>
                                </> 
                            : null }
                            { solution.for_organization_pt ? 
                                <>
                                    <div className="row m-top-20">
                                        <p className="f-f-flayfair f-bold f-s-14 c-blue" style={{ color: solution.primary_focus[0].color }}>{i18n.language === "pt" ? "Para a sua organização" : "For your organization"}</p>
                                    </div>
                                    <div className="row m-top-10">
                                        <p className="f-s-14" dangerouslySetInnerHTML={{ __html: i18n.language === "pt" ? solution.for_organization_pt : solution.for_organization_en }}></p>
                                    </div>
                                </> 
                            : null }
                        </div>
                    </div>
                </div>
                { !open ? 
                    <div className="buttons-bottom" style={{width: '100%', opacity: printing ? 0 : 1}}>
                        <div className="row sep m-top-35"></div>
                        <div className="row flex m-top-35 bottom">
                            { renderButtons() }
                        </div>
                    </div>
                : null }
               
                {pdf ? <PdfPreview file={pdf} solution={solution} added={added} addToPortfolio={this.onAddToPortfolio} removeFromPortfolio={this.onRemoveFromPortfolio} popupForm={this.onPopup} onClosePdf={() => this.setState({ pdf: null })} /> : null}
                {video ? <ModalVideo channel='youtube' isOpen={video} videoId={i18n.language === "pt" ? this.getYouTubeId(solution.video_pt) : this.getYouTubeId(solution.video_en)} onClose={() => this.setState({ video: false })} /> : null}
                <ToastContainer />
                <PopupForm show={downloadForm} onClose={this.onPopup} portfolio={[solution.id]} onPrint={this.onPrint}/>
                <ReactToPrint
                    trigger={() => <React.Fragment/>}
                    content={() => this.componentRef}
                    ref={this.printRef}
                    onBeforePrint={() => this.setState({ printing: true })}
                    onAfterPrint={() => { 
                        this.setState({ printing: false });
                        this.onPopup() 
                    }}
                />
            </div>
            </>
        )
    }
}

export default withTranslation()(Card);