import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { ServicesContext } from '../context/services';
import MediaQuery from 'react-responsive';
import './Sidebar.scss';
import CheckboxCircle from "./ui/CheckboxCircle";
import CheckboxIcon from "./ui/CheckboxIcon";
import CheckSimple from "./ui/CheckSimple";
import IntervalEqualizer from "./ui/IntervalEqualizer";
import TagList from '../components/ui/tags/TagList';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filtersList: null,
            types: localStorage.getItem('iter:filter:types'),
            tab: "advanced",
            showSector: false
        }

        this.onChange = this.onChange.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
        this.hasType = this.hasType.bind(this);

        this.onFilter = this.props.onFilter;
        this.onReset = this.props.onReset;

        this.typesFilter = React.createRef();
        this.listTagFilter = React.createRef();
        this.experienceFilter = React.createRef();
        this.methodologyFilter = React.createRef();
        this.formatFilter = React.createRef();
        this.scholarshipFilter = React.createRef();
        this.seniorityFilter = React.createRef();
        this.languageFilter = React.createRef();
        this.locationFilter = React.createRef();
        this.sectorFilter = React.createRef();
        this.sectorEl = React.createRef();
    }

    onChange(filterKey, filters) {
        this.onFilter(filterKey, filters);
        /*
        if(filterKey === "primary_focus"){
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];
                if(filter.note) this.sectorValidation();
            }
        }*/
    }

    resetHandler() {
        if (this.typesFilter.current) this.typesFilter.current.reset();
        if (this.listTagFilter.current) this.listTagFilter.current.reset();
        if (this.experienceFilter.current) this.experienceFilter.current.reset();
        if (this.methodologyFilter.current) this.methodologyFilter.current.reset();
        if (this.formatFilter.current) this.formatFilter.current.reset();
        if (this.scholarshipFilter.current) this.scholarshipFilter.current.reset();
        if (this.seniorityFilter.current) this.seniorityFilter.current.reset();
        if (this.languageFilter.current) this.languageFilter.current.reset();
        if (this.locationFilter.current) this.locationFilter.current.reset();
        if (this.sectorFilter.current) this.sectorFilter.current.reset();
        this.setState({ showSector: false });
        this.onReset();
    }

    //todo
    sectorValidation(state) {
        const showSector = this.state.showSector;
        const sectorEl = this.sectorEl;
        if (state) {
            if (!showSector) {
                this.setState({ showSector: true });
                setTimeout(function () {
                    if (sectorEl.current) sectorEl.current.scrollIntoView({ behavior: "smooth", });
                }, 500)
            }
        }
    }

    updateEqualizers() {
        // if (this.methodologyFilter && this.methodologyFilter.current) this.methodologyFilter.current.updateValues();
        // if (this.seniorityFilter && this.seniorityFilter.current) this.seniorityFilter.current.updateValues();
    }

    componentDidMount() {
        const { services } = this.context;

        services.filtersApi.getAll()
            .then((data) => {
                this.setState({ filtersList: data });
            }).catch(error => console.log(error));
    }

    onTabChange(area) {
        if (area) this.setState({ tab: area });
    }

    handleMediaQueryChange(matches) {
        if (!matches) this.setState({ tab: "areas" })
        else this.setState({ tab: "advanced" })
        // matches will be true or false based on the value for the media query
    }

    toggleSideBar(e) {
        this.props.onToggleSidebar(e)
    }

    hasType(type_label) {
        const localStorageTypes = localStorage.getItem('iter:filter:types');
        let tmpState = false;
        if (!localStorageTypes) tmpState = true;
        else {
            JSON.parse(localStorageTypes).map((type, index) => {
                if (type_label === 'B2B' && type.icon === 'icon-empresa' && type.checked === true) tmpState = true;
                if (type_label === 'B2C' && type.icon === 'icon-particular' && type.checked === true) tmpState = true;
            });
            return tmpState;
        }
    }

    parseByType(list) {
        let newList = [];
        let selected = {}, item, i, k;

        for(i = 0; i < this.props.type.length; i++) {
            if(this.props.type[i].checked) {
                selected[this.props.type[i].id] = true;
            }
        }

        for(i = 0; i < list.length; i++) {
            item = list[i];
            for(k = 0; k < item.type.length; k++) {
                if(selected[item.type[k].id]) {
                    newList.push(item);
                    break;
                }
            }
        }

        return newList;
    }

    render() {
        const { t, filters } = this.props;
        let { filtersList, tab } = this.state;

        let showSector = false;
        //sector filter validation
        if (filters['primary_focus']) {
            for (let i = 0; i < filters['primary_focus'].length; i++) {
                const filter = filters['primary_focus'][i];
                if (filter.note) showSector = true; //todo 
            }
        }

        if (this.props.type === "B2C" && filtersList) {
            filtersList.allLocations = filtersList.allLocations.filter(option => {
                if (option.icon !== "icon-empresa") return option;
            });
        }

        let allExperienceTypes;

        if(filtersList) {
            allExperienceTypes = this.props.type && this.props.type.length ? this.parseByType(filtersList.allExperienceTypes) : filtersList.allExperienceTypes;
        }

        return (
            <div className="sidebar">
                <MediaQuery maxWidth={767}>
                    <div className="close-sidebar">
                        <div className="close-button" onClick={this.toggleSideBar.bind(this)}>
                            <span className="icon-close"></span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={1024} onChange={this.handleMediaQueryChange.bind(this)}>
                    <h3 className="f-f-flayfair f-bold f-s-24">{t('sidebar.advanced_filters', 'Advanced filters')}</h3>
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <div className="row flex tab-buttons">
                        <div className={tab === "areas" ? "tab-button active" : "tab-button"} onClick={this.onTabChange.bind(this, "areas")}>Areas</div>
                        <div className={tab === "advanced" ? "tab-button active" : "tab-button"} onClick={this.onTabChange.bind(this, "advanced")}>{t('sidebar.advanced_filters', 'Advanced filters')}</div>
                    </div>
                    <div style={{ paddingLeft: 0 }}>
                        {tab === "areas" && filtersList && filtersList.allTransformationFoci ? <TagList ref={this.listTagFilter} id="primary_focus" title={t('dashboard.transformation_focus', 'Focus Transformation')} onFilterChange={this.onChange} options={filtersList.allTransformationFoci} /> : null}
                    </div>
                </MediaQuery>

                {tab === "advanced" && filtersList && filtersList.allUserTypes ? <CheckboxIcon ref={this.typesFilter} id="types" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.types', 'Tipo de utilizador')} options={filtersList.allUserTypes} /> : null}
                {tab === "advanced" && filtersList && allExperienceTypes ? <CheckboxCircle ref={this.experienceFilter} id="experience" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.experience_type', 'Experience Type')} options={allExperienceTypes} /> : null}
                {tab === "advanced" && filtersList && filtersList.allLearningMethodologies ? <IntervalEqualizer ref={this.methodologyFilter} id="learning_methodology" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.learning_methodology', 'Learning Methodology')} options={filtersList.allLearningMethodologies} type={this.props.type} filters={filters} /> : null}
                {tab === "advanced" && filtersList && filtersList.allLearningFormats ? <CheckboxIcon ref={this.formatFilter} id="format" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.learning_format', 'Learning Format')} options={filtersList.allLearningFormats} /> : null}
                {tab === "advanced" && filtersList && filtersList.allScholarships ? <CheckboxIcon ref={this.scholarshipFilter} id="scholarship" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.scholarship_eligible', 'Scholarship Eligible')} options={filtersList.allScholarships} /> : null}
                {tab === "advanced" && filtersList && filtersList.allSeniorities ? <IntervalEqualizer ref={this.seniorityFilter} id="seniority" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.seniority', 'Seniority')} options={filtersList.allSeniorities} type={this.props.type} filters={filters} /> : null}
                {tab === "advanced" && filtersList && filtersList.allLanguages ? <CheckSimple ref={this.languageFilter} id="language" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.language', 'Language')} options={filtersList.allLanguages} /> : null}
                {tab === "advanced" && filtersList && filtersList.allLocations ? <CheckboxIcon ref={this.locationFilter} id="location" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.location_format', 'Location')} options={filtersList.allLocations} /> : null}
                {tab === "advanced" && filtersList && filtersList.allSectors ? <div style={{ display: showSector ? 'block' : 'none ' }}> <CheckboxCircle ref={this.sectorFilter} sectorEl={this.sectorEl} id="sector" onFilterChange={this.onChange} lang={this.props.i18n.language} title={t('filters.sector', 'Sector')} options={filtersList.allSectors} /></div> : null}

                <div className="bottom">
                    <MediaQuery minWidth={768}>
                        <div className="btn" onClick={this.resetHandler}>{t('sidebar.clear_all_filters', 'Clear All Filters')}</div>
                    </MediaQuery>
                    <MediaQuery maxWidth={767}>
                        <div className="row flex buttons jc-sb no-wrap" style={{ gap: '10px' }}>
                            <div className="button secundary grow" onClick={this.resetHandler}>{t('sidebar.clear_filters', 'Clear All Filters')}</div>
                            <div className="button grow" onClick={this.toggleSideBar.bind(this)}>{t('sidebar.apply_filters', 'Apply Filters')}</div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
        )
    }
}


Sidebar.contextType = ServicesContext;
export default withTranslation('translation', { withRef: true })(Sidebar);