export default function makeCampaignsApi({
	query,
	mutation,
}) {
    const campaignFragment = `{
        id
        campaign_id,
        solutions {
            id
        }
    }`;

	return {
        getById: id => query(`
			query {
                allCampaigns(where: { campaign_id: "${id}" } ) ${campaignFragment}
            }
        `),

        getBySlug: slug => query(`
			query {
                allCampaigns(where: { slug: "${slug}" } ) ${campaignFragment}
            }
        `),
	};
}