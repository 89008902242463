import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'pt',
    fallbackLng: 'pt',
    whitelist: ['pt', 'en'],
    returnObjects: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });
 
export default i18n;