import React, { Component } from "react";
import { withTranslation  } from 'react-i18next';
import { ServicesContext } from '../../context/services';
import './PopupSearch.scss';

class PopupSearch extends Component {
    constructor(props) {    
        super(props);
        this.state = {
            value: '',
            typing: false,
            typingTimeout: 0,
            solutions: []
        }
        this.onCloseSearch = this.props.onCloseSearch;
    }

    handleChange(event){
        const self = this,
            value = event.target.value;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            value: value,
            typing: false,
            typingTimeout: setTimeout(function () {
                if(value.length !== 0 && value.length > 3) self.searchSolution(self.state.value);
                else self.setState({solutions: []});
                }, 300)
        });
    }

    searchSolution(value) {
        const { services } = this.context;
        //var res = value.split(" ");
        // res.map(value => (
        //     this.props.i18n.language === "pt" ? 
        //     query+= '{ pt_contains_i: "'+value+'" }, { summary_pt_contains_i: "'+value+'" }, { speaker_name_contains_i: "'+value+'" }, { speaker_title_pt_contains_i: "'+value+'" },' 
        //     : 
        //     query+= '{ en_contains_i: "'+value+'" }, { summary_en_contains_i: "'+value+'" }, { speaker_name_contains_i: "'+value+'" }, { speaker_title_en_contains_i: "'+value+'" },'
        // ));

        let query = "";
        this.props.i18n.language === "pt" ? 
        query+= '{ pt_contains_i: "'+value+'" }, { speaker_name_contains_i: "'+value+'" },' 
        : 
        query+= '{ en_contains_i: "'+value+'" }, { speaker_name_contains_i: "'+value+'" },'
    
        query = query.slice(0, -1);
        
        services.solutionsApi.searchSolution(query)
        .then(({ allSolutions }) => {
            console.log(allSolutions)
            this.setState({solutions: allSolutions});
        }).catch(error => {
           // window.location = "/"
        });
    }

    onClose(){
        this.setState({solutions: [], value: ''});
        this.onCloseSearch();
    }


    getDetailURL(program){
        let url = '/';
        const slug = this.props.i18n.language === 'pt' ? program.url_pt : program.url_en;
        url += 'detail/' + slug;
        //url += program.id;
        return url;
    } 
    
    render() {
        const { t } = this.props;
        const {solutions} =this.state;

        return (
            this.props.show ? <div className="viewport">
                <div className="search-modal a-center">
                    <div className="field">
                        <div className="row flex pad-25">
                            <span className="icon-search f-s-24"></span>
                            <input id="search" label="search" placeholder={this.props.i18n.language === "pt" ? "Pesquisar solução" : "Search solution"} onChange={this.handleChange.bind(this)} className="f-s-16" />
                        </div>
                        <div className="close-btn" onClick={this.onClose.bind(this)}>
                            <span className="icon-delete f-s-24"></span>
                        </div>
                    </div>
                    <div className="row">
                       { solutions && solutions.length > 0 ? 
                         <ul className="scrollbar">
                         {   
                             solutions.map(solution => (
                                 <li key={solution.id}>
                                     <a href={this.getDetailURL(solution)} target="_self">
                                        <div className="detail">
                                            <span className="row cat">{solution.experience ? solution.experience[this.props.i18n.language] : ''}</span>
                                            <span className="name" style={{color: solution.primary_focus[0].color}}>
                                                { solution[this.props.i18n.language] }
                                            </span>
                                        </div>
                                    </a>
                                 </li>
                             ))
                         }
                         </ul>
                        : null }
                    </div>
                </div>
            </div> : null
        )
    }
}

PopupSearch.contextType = ServicesContext;
export default withTranslation()(PopupSearch);