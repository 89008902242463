export default function makeSolutionsApi({
    query,
    mutation,
}) {

    const solutionDetailedFragment = `{
    id
    initials
    pt
    en
    summary_pt
    summary_en
    for_you_pt
    for_you_en
    for_organization_pt
    for_organization_en
    duration_pt
    duration_en
    speaker_name
    speaker_title_pt
    speaker_title_en
    speaker_bio_pt
    speaker_bio_en
    speaker_languages_pt
    speaker_languages_en
    organization
    speaker_photo {
        publicUrl
    }
    type {
        id
        pt
        en
    }
    types {
        id
        pt
        en
        target
    }
    primary_focus {
        id
        pt
        en
        color
    }
    secundary_focus {
        id
        pt
        en
    }
    experience {
        id
        pt
        en
    }
    methodology {
        id
        pt
        en
    }
    format {
        id
        pt
        en
        icon
    }
    scholarship {
        id
        pt
        en
        icon
    }
    min_seniority {
        id
        pt
        en
        value
    }
    max_seniority {
        id
        pt
        en
        value
    }
    languages {
        id
        pt
        en
    }
    location {
        id
        pt
        en
    }
    sector {
        id
        pt
        en
    }
    has_landing_page,
    landing_page,
    file_en {
        publicUrl
    }
    file_pt{
        publicUrl
    }
    video_en
    video_pt
    contact_info_name
    contact_info_email
    contact_info_phone
    url_pt
    url_en
}`;

    const solutionFragment = `{
      id
      initials
      pt
      en
      summary_pt
      summary_en
      type {
          id
          pt
          en
      }
      types {
          id
          pt
          en
          target
      }
      primary_focus {
          id
          pt
          en
          color
      }
      secundary_focus {
          id
          pt
          en
      }
      experience {
          id
          pt
          en
      }
      methodology {
          id
      }
      format {
          id
          pt
          en
      }
      scholarship {
        id
        pt
        en
      }
      min_seniority {
          id
          pt
          en
      }
      max_seniority {
          id
          pt
          en
      }
      languages {
          id
          pt
          en
      }
      location {
          id
          pt
          en
      }
      sector {
          id
          pt
          en
      }
      url_pt
      url_en
    }`;

    const liteFragment = `{
        id
        initials
        pt
        en
        speaker_name
        type {
            id
            pt
            en
        }
        types {
            target
        }
        primary_focus {
            id
            color
        }
        experience {
            pt
            en
        }
        url_pt
        url_en
      }`;

    return {

        getAll: () => query(`
            query {
                allSolutions(where: { state: published }) ${liteFragment}
            }
        `),
        getAllByTarget: target => query(`
            query {
                allSolutions(where: { state: published, types: { target: ${target.toUpperCase()} } }) ${liteFragment}
            }
        `),
        getSolutionFiltered: (filters) => query(`
          query {
                  allSolutions(where: ${filters} ) ${liteFragment}
              }
        `),
        getSolutionDetail: detailId => query(`
			query {
                allSolutions(where: { id: "${detailId}" } ) ${solutionDetailedFragment}
            }
        `),
        getSolutionDetailBySlug: (slug) => query(`
			query {
                allSolutions(where: {
                OR: [
                    {url_pt:  "${slug}"}, 
                    {url_en:  "${slug}"}, 
                ] } ) ${solutionDetailedFragment}
            }
        `),
        getSolutionDetailByTypeAndSlug: (type, slug) => query(`
			query {
                allSolutions(where: { types: { target: ${type.toUpperCase()} },
                OR: [
                    {url_pt:  "${slug}"}, 
                    {url_en:  "${slug}"}, 
                ] } ) ${solutionDetailedFragment}
            }
        `),
        getRelatedSolutions: (not_id, primary_focus_id, types_id) => query(`
            query {
                allSolutions(where: { 
                    state: published, 
                    id_not: "${not_id}", 
                    primary_focus_some: { id_in: ${primary_focus_id} } 
                } ) ${liteFragment}
            }
        `),
        getRelatedFocusSecundarySolutions: (not_id, primary_focus_id, types_id, secundary_focus) => query(`
            query {
                allSolutions(where: { 
                    state: published, 
                    id_not: "${not_id}", 
                    OR: [
                        {primary_focus_some: { id_in: ${primary_focus_id} }},
                        {secundary_focus_some:  ${secundary_focus}}
                    ]
                } ) ${liteFragment}
            }
        `),
        getRelatedSenioritySolutions: (not_id, primary_focus_id, types_id, secundary_focus, seniority_min, seniority_max) => query(`
            query {
                allSolutions(where: { 
                    state: published, 
                    id_not: "${not_id}", 
                    OR: [
                        {primary_focus_some: { id_in: ${primary_focus_id} }},
                        {secundary_focus_some:  ${secundary_focus}}
                    ],
                    AND: [
                        {min_seniority: {value_lte: ${seniority_max}}}, 
                        {max_seniority: {value_gte: ${seniority_min}}}
                    ]
                } ) ${liteFragment}
            }
        `),
        getRelatedFormatSolutions: (not_id, primary_focus_id, types_id, secundary_focus, seniority_min, seniority_max, format) => query(`
            query {
                allSolutions(where: { 
                    state: published, 
                    id_not: "${not_id}", 
                    OR: [
                        {primary_focus_some: { id_in: ${primary_focus_id} }},
                        {secundary_focus_some:  ${secundary_focus}}
                    ],
                    AND: [
                        {min_seniority: {value_lte: ${seniority_max}}}, 
                        {max_seniority: {value_gte: ${seniority_min}}}
                    ],
                    format_some: ${format}
                } ) ${liteFragment}
            }
        `),
        getPortfolio: ids => query(`
			query {
                allSolutions(where: { id_in: ${ids} } ) ${solutionDetailedFragment}
            }
        `),

        getMethodologyLength: (id, filters) => query(`
			query {
                _allSolutionsMeta(where: { methodology: { id: "${id}" }, ${filters} }) {count}
            }
        `),

        getMinSeniorityLength: (value, filters) => query(`
			query {
                _allSolutionsMeta(where: { AND: [ { min_seniority: { value_lte:  ${value} } } { max_seniority: { value_gte:  ${value} } } ], ${filters}  }) {count}
            }
        `),

        getMaxSeniorityLength: (id, filters) => query(`
			query {
                _allSolutionsMeta(where: { max_seniority: { id: "${id}" }, ${filters}  }) {count}
            }
        `),
        
        searchSolution: (values) => query(`
            query {
                allSolutions( 
                    sortBy: initials_ASC,
                    first: 10,
                    where: { 
                        OR: [ ${values} ], 
                        state: published
                    }
                )
                ${liteFragment}
            }
        `),
    };
}