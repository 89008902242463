import React, { Component } from "react";
import { ServicesContext } from '../../context/services';
import './CheckSimple.scss';

class CheckSimple extends Component {
    constructor(props) {    
      super(props);
      
      const savedOptions = JSON.parse(localStorage.getItem('iter:filter:'+this.props.id));
      
        this.state = {
            options: savedOptions && savedOptions.length > 0 ? savedOptions : this.props.options
        };
    }
    /*
    componentDidMount(){
        if(!this.savedOptions){
            const { services } = this.context;
            
            switch (this.props.id) {
                case "language":
                        services.filtersApi.getAllLanguages()
                        .then(({ allLanguages }) => {
                            this.setState({options: allLanguages});
                        }).catch(error => console.log(error));
                    break;
                default:
                    break;
            }
        }
    }
    */
    
    reset(){
        if(this.state.options){
            const options = this.state.options;
            options.filter(option => {
                option.checked = false;
            });
            //this.props.onFilterChange( this.props.id, [] );
        }
        localStorage.removeItem('iter:filter:'+this.props.id);
    }

    handleChange (index, event) {
        const options = this.state.options;
        options[index].checked = event.currentTarget.checked;
        
        const optionChecked = options.filter(option => {
            if(option.checked === true) return option;
        });

        this.props.onFilterChange( this.props.id, optionChecked );
        localStorage.setItem('iter:filter:'+this.props.id,JSON.stringify(options));
    }
    render() {
        const { id, title } = this.props;
        const { options } = this.state;
        return (
             <div className="row m-top-35">
                <h4 className="f-f-flayfair f-bold f-s-14">{title}</h4>
                <div className="row flex m-top-15">
                    {options ? options.map((item, index) => (
                        <div key={item.id} className="checksimple">
                            <input type="checkbox" id={item.id} name={id} onChange={this.handleChange.bind(this, index)} checked={item.checked ? true : false} />
                            <label htmlFor={item.id} className="">{item[this.props.lang]}</label>
                        </div>
                    )) : null }
                </div>
            </div>
        )
    }
}
CheckSimple.contextType = ServicesContext;

export default CheckSimple;